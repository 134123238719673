import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import dayjs from "dayjs";

import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

import { DefaultPaper, PricingToggle } from "@aclymatepackages/atoms";
import { formatDate, formatDollars } from "@aclymatepackages/formatters";

import CollapsibleAlert from "../../atoms/notifications/CollapsibleAlert";
import ErrorBoundary from "../../atoms/ErrorBoundary";
import Link from "../../atoms/mui/Link";
import ProductSignUpForm from "../../inputs/flows/ProductSignupForm";
import CreditCardSlider from "../../inputs/CreditCardSlider";
import StripeElements from "../../modules/StripeElements";

import { useSubscriptionProducts } from "../../../helpers/components/settings";
import { useSubscriptionType } from "../../../helpers/hooks/companyData";
import { StripeCustomerContext } from "../../../helpers/contexts/stripeCustomer";
import { isObjectEmpty } from "../../../helpers/otherHelpers";

const PricingCard = ({
  product,
  title,
  price,
  caption,
  bullets,
  employeeCount,
  selectedInterval,
  buttonLabel,
  endDate,
}) => {
  const { palette } = useTheme();

  const blurryPrice = employeeCount > 250 ? { filter: "blur(15px)" } : {};

  return (
    <DefaultPaper
      style={{
        height: "100%",
      }}
    >
      <Grid container direction="column" spacing={4}>
        <Grid item>
          <Typography variant="h6" align="center">
            {title}
          </Typography>
          <Typography variant="h2" align="center" style={blurryPrice}>
            {formatDollars(price, 0)}
          </Typography>
          <Typography variant="subtitle2" align="center">
            {caption}
          </Typography>
        </Grid>
        <Grid
          item
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            <Link
              href={`/platform/company/settings/products/${product}?interval=${selectedInterval}`}
            >
              <Button color="secondary" variant="contained" size="large">
                {buttonLabel || "Sign Up"}
              </Button>
            </Link>
          </Grid>
          {endDate && (
            <Grid item>
              <Typography variant="caption" component="h6" align="center">
                You've canceled your subscription.
                <br /> It will end on {formatDate(endDate)}
              </Typography>
            </Grid>
          )}
        </Grid>
        <Grid item>
          {product === "concierge" && (
            <Typography variant="subtitle2">
              Everything in our Professional plan plus...
            </Typography>
          )}
          {product === "integrations" && (
            <Typography variant="subtitle2">
              Everything in our Starter plan plus...
            </Typography>
          )}
          <List style={{ padding: 0 }}>
            {bullets.map((bullet, idx) => (
              <ListItem key={`feature-card-list-item-${idx}`}>
                <ListItemIcon>
                  <CheckCircleIcon style={{ color: palette.primary.main }} />
                </ListItemIcon>
                <ListItemText>{bullet}</ListItemText>
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    </DefaultPaper>
  );
};

const TieredPricingBlock = ({ products, selectedInterval, employeeCount }) => {
  const displayPrice = (prices = []) => {
    const { value: price } = prices.find(
      ({ interval }) => interval === selectedInterval
    );

    if (selectedInterval === "month") {
      return price;
    }

    return Math.floor(price / 12);
  };

  const caption =
    selectedInterval === "year" ? "per month (paid yearly)" : "per month";

  return (
    <Container maxWidth="lg">
      <Grid container spacing={2} justifyContent="center">
        {products.map(
          ({ product, prices, buttonLabel, ...otherProps }, idx) => (
            <Grid item sm={4} key={`product-card-${idx}`}>
              <PricingCard
                {...otherProps}
                price={displayPrice(prices)}
                product={product}
                buttonLabel={buttonLabel}
                caption={caption}
                employeeCount={employeeCount}
                selectedInterval={selectedInterval}
              />
            </Grid>
          )
        )}
      </Grid>
    </Container>
  );
};

const AccountPricingBlock = ({
  selectedInterval,
  subscriptionObj,
  subscriptionLoading,
  subscriptionProducts,
  subscriptionProductsLoading,
}) => {
  const buildFormattedProducts = () => {
    const {
      type: subscriptionType,
      status,
      interval,
      endDate,
    } = subscriptionObj;

    if (subscriptionProductsLoading || subscriptionLoading) {
      return <></>;
    }

    return subscriptionProducts.map((productObj, idx) => {
      const buildProductUpgradeLabel = () => {
        const currentSubscriptionProductIdx = subscriptionProducts.findIndex(
          ({ product }) => product === subscriptionType
        );

        if (status === "active" && idx > currentSubscriptionProductIdx) {
          return { ...productObj, buttonLabel: "upgrade" };
        }

        return productObj;
      };

      if (
        status === "trialing" ||
        status === "expired" ||
        productObj.product !== subscriptionType
      ) {
        return buildProductUpgradeLabel();
      }

      if (status === "canceled" && new Date(endDate) > new Date()) {
        return {
          ...productObj,
          endDate,
          buttonLabel: `restart or view ${interval}ly plan`,
        };
      }

      return {
        ...productObj,
        buttonLabel: `view current ${interval}ly plan`,
      };
    });
  };

  const formattedSubscriptionProducts = buildFormattedProducts();

  return (
    <TieredPricingBlock
      products={formattedSubscriptionProducts}
      selectedInterval={selectedInterval}
    />
  );
};

const ProductsDisplay = ({ action, onBackButtonClick }) => {
  const history = useHistory();
  const { paymentMethod, isPaymentMethodLoading } = useContext(
    StripeCustomerContext
  );
  const { card } = paymentMethod || {};
  const { expirationDate } = card || {};

  const [subscriptionObj, subscriptionLoading] = useSubscriptionType("saas");
  const [subscriptionProducts, subscriptionProductsLoading] =
    useSubscriptionProducts();

  const [showBillingInput, setShowBillingInput] = useState(false);
  const [selectedPriceInterval, setSelectedPriceInterval] = useState("year");

  const pageLoading =
    subscriptionProductsLoading ||
    subscriptionLoading ||
    isPaymentMethodLoading;

  const selectedProduct = subscriptionProducts.find(
    (productObj) => productObj?.product === action
  );
  const isPaymentMethodExpired =
    dayjs(expirationDate).isBefore(dayjs()) || isObjectEmpty(paymentMethod);

  return (
    <>
      {!pageLoading && (
        <>
          {showBillingInput && (
            <StripeElements>
              <CreditCardSlider
                setSliderOpen={() => setShowBillingInput(false)}
              />
            </StripeElements>
          )}
          {action && selectedProduct && (
            <ProductSignUpForm
              {...selectedProduct}
              onClose={() =>
                history.push("/platform/company/settings/products")
              }
            />
          )}
          <Grid container direction="column" spacing={2}>
            {isPaymentMethodExpired && (
              <Grid item>
                <CollapsibleAlert
                  title="Your Credit Card is Expired"
                  subtitle="Enter a new credit to be able to purchase a new subscription"
                  action={
                    <Button
                      color="inherit"
                      size="small"
                      onClick={() => setShowBillingInput(true)}
                    >
                      Update Billing Method
                    </Button>
                  }
                />
              </Grid>
            )}
            <Grid
              item
              container
              justifyContent="center"
              alignItems="center"
              sx={{ position: "relative" }}
            >
              {onBackButtonClick && (
                <Box position="absolute" left={0} marginLeft={3}>
                  <IconButton onClick={onBackButtonClick} size="large">
                    <ChevronLeftIcon />
                  </IconButton>
                </Box>
              )}
              <Grid item>
                <PricingToggle
                  selectedPriceInterval={selectedPriceInterval}
                  setSelectedPriceInterval={setSelectedPriceInterval}
                />
              </Grid>
            </Grid>
            <Grid item>
              <AccountPricingBlock
                selectedInterval={selectedPriceInterval}
                subscriptionObj={subscriptionObj}
                subscriptionLoading={subscriptionLoading}
                subscriptionProducts={subscriptionProducts}
                subscriptionProductsLoading={subscriptionProductsLoading}
              />
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

const Products = (props) => (
  <ErrorBoundary>
    <ProductsDisplay {...props} />
  </ErrorBoundary>
);
export default Products;
