import { useState } from "react";

import useCsvUploader from "./csvUploader";

const fieldOptions = [
  { label: "Date", value: "date" },
  { label: "Vendor", value: "vendor" },
  { label: "Value", value: "value" },
  { label: "Category", value: "category" },
];

const requiredFields = ["Date", "Vendor", "Value", "Category"];

const knownFields = [
  { header: "date", field: "date" },
  { header: "description", field: "vendor" },
  { header: "vendor", field: "vendor" },
  { header: "memo", field: "vendor" },
  { header: "value", field: "value" },
  { header: "total", field: "value" },
  { header: "amount", field: "value" },
  { header: "category", field: "category" },
  { header: "description", field: "category" },
];

const fieldValidator = (headers) => {
  const fieldsBoolean = requiredFields.reduce(
    (booleanAcc, requiredField) =>
      booleanAcc &&
      headers.find(({ field }) => requiredField.toLowerCase() === field),
    true
  );

  return {
    success: fieldsBoolean,
    message:
      !fieldsBoolean &&
      `The fields Date, Vendor,${
        requiredFields.includes("Category") ? " Category," : ""
      } and Value are required for this CSV. Exclude Category to select your own categories for each transaction.`,
  };
};

const useTransactionsUploader = ({ open, setOpen }) => {
  const [fileInfo, setFileInfo] = useState({});
  const [matchedHeaders, setMatchedHeaders] = useState([]);

  const csvUploaderSteps = useCsvUploader({
    knownFields,
    fieldOptions,
    open,
    setOpen,
    fileInfo,
    setFileInfo,
    matchedHeaders,
    setMatchedHeaders,
    fieldValidator,
    docType: "transactions",
    firstStepText:
      "Upload a CSV of your transactions and we'll automatically sort out the ones that you can offset.",
    requiredFields,
  });

  return csvUploaderSteps;
};
export default useTransactionsUploader;
