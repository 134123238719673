import { useState } from "react";
import { useParams } from "react-router";

import {
  faCheck,
  faQuestionCircle,
  faReceipt,
  faListCheck,
} from "@fortawesome/free-solid-svg-icons";
import {
  faBlockQuestion,
  faBagShopping,
  faBulldozer,
  faTruckFast,
  faPassport,
  faTrashCan,
  faDolly,
} from "@fortawesome/pro-solid-svg-icons";

import { findVendorEmissionCategoryData } from "@aclymatepackages/calcs/vendors";
import { letterSBoolean } from "@aclymatepackages/formatters";
import { sumTonsCo2e } from "@aclymatepackages/other-helpers";
import mainTheme from "@aclymatepackages/themes";

import { splitAggregatedTransactionsBreakdownSubcategories } from "./emissions";
import { useCachedFirebaseCrud, useAuth } from "../firebase";
import { fetchOurApi, useApiData } from "../utils/apiCalls";
import { getAccountCollectionAndId } from "../otherHelpers";

export const scopeThreeSpendBasedCategories = [
  {
    label: "Purchased Goods and Services",
    value: 1,
    subcategory: "purchased-goods-services",
    icon: faBagShopping,
  },
  {
    label: "Capital Goods",
    value: 2,
    subcategory: "capital-goods",
    icon: faBulldozer,
  },
  {
    label: "Upstream Transportation and Distribution",
    value: 4,
    subcategory: "upstream-transport-distribution",
    icon: faTruckFast,
  },
  {
    label: "Waste Generated in Operations",
    value: 5,
    subcategory: "waste-generated-in-operations",
    icon: faTrashCan,
  },
  {
    label: "Business Travel",
    value: 6,
    subcategory: "business-travel",
    icon: faPassport,
  },
  {
    label: "Downstream Transportation and Distribution",
    value: 9,
    subcategory: "downstream-transportation-and-distribution",
    icon: faDolly,
  },
  {
    label: "None",
    value: -1,
  },
];

export const vendorStatuses = {
  confirmed: {
    id: "confirmed",
    icon: faCheck,
    severity: 0,
    tooltip: "This vendor is confirmed and has confirmed transactions",
    name: "Confirmed Vendors",
    color: mainTheme.palette.secondary.main,
  },
  uncategorized: {
    id: "uncategorized",
    severity: 2,
    tooltip:
      "This vendor hasn't been categorized so their emissions haven't been added to your balance.",
    name: "Uncategorized Vendors",
    icon: faQuestionCircle,
    color: mainTheme.palette.error.main,
  },
  noTransactions: {
    id: "noTransactions",
    icon: faReceipt,
    tooltip:
      "This vendor has been categorized but doesn't have any transactions",
    name: "No transactions",
    color: mainTheme.palette.error.light,
    severity: 1,
  },
  unconfirmed: {
    id: "unconfirmed",
    icon: faListCheck,
    name: "Unconfirmed",
    color: mainTheme.palette.error.dark,
    severity: 3,
  },
  unmatched: {
    id: "unmatched",
    icon: faBlockQuestion,
    tooltip: "This vendor hasn't been matched yet.",
    name: "Unmatched",
    color: mainTheme.palette.text.secondary,
    severity: 4,
  },
};

export const findVendorStatus = ({ emissionCategory, transactions }) => {
  if (emissionCategory && transactions.length) {
    return vendorStatuses.confirmed;
  }

  if (emissionCategory) {
    return vendorStatuses.noTransactions;
  }

  return vendorStatuses.uncategorized;
};

export const formatVendors = ({
  matchedVendors,
  unmatchedVendors,
  matchedVendorTransactions,
}) => {
  //This is the preliminary vendor score V0
  //For now we're doing just a 3 star score, so we want numbers that are easy multiples of 3.
  //But, we're going to store the score as a numeric 0-100 scale for future versions where we develope a more robust scoring system
  const findVendorScore = ({
    vendorData = {},
    stripeCustomerId,
    revenueYears = [],
  }) => {
    if (stripeCustomerId && revenueYears.length) {
      return 90;
    }

    const { surveys } = vendorData;
    if (!surveys) {
      return 30;
    }

    const [{ dateCompleted }] = surveys;
    if (!dateCompleted) {
      return 30;
    }

    return 60;
  };

  const findVendorSurveyStatus = ({ vendorData, stripeCustomerId }) => {
    if (stripeCustomerId) {
      return "has-account";
    }

    const { surveys } = vendorData || {};
    if (!vendorData || !surveys) {
      return "no-survey";
    }

    const [{ dateCompleted }] = surveys;
    if (dateCompleted) {
      return "survey-completed";
    }

    return "survey-sent";
  };

  const extractAndSumVendorTransactions = (aggregatedTransactions) => {
    const vendorTransactions =
      splitAggregatedTransactionsBreakdownSubcategories(aggregatedTransactions);
    const totalTransactionsCount = vendorTransactions.reduce(
      (acc, { transactionsCount }) => acc + transactionsCount,
      0
    );

    const tonsCo2e = sumTonsCo2e(vendorTransactions);

    return {
      transactions: vendorTransactions,
      transactionsCount: totalTransactionsCount,
      tonsCo2e,
      avgTonsCo2ePerTransaction: tonsCo2e / (totalTransactionsCount || 1),
    };
  };

  const formatMatchedVendors = (vendor) => {
    const { id, name, vendorData, ...otherProps } = vendor;

    const { aggregatedTransactions = [] } =
      matchedVendorTransactions.find(({ companyId }) => companyId === id) || {};

    const vendorCategoryProps = findVendorEmissionCategoryData(vendor) || {};

    const vendorTransactionProps = extractAndSumVendorTransactions(
      aggregatedTransactions
    );

    return {
      ...otherProps,
      id,
      name,
      ...vendorCategoryProps,
      surveyStatus: findVendorSurveyStatus(vendor),
      vendorScore: findVendorScore(vendor),
      ...vendorData,
      ...vendorTransactionProps,
    };
  };

  const formattedMatchedVendors = matchedVendors.map((vendor) => {
    const formattedVendor = formatMatchedVendors(vendor);

    const { transactions, emissionCategory } = formattedVendor;

    const { id: status, severity } = findVendorStatus({
      emissionCategory,
      transactions,
    });

    return {
      ...formattedVendor,
      status,
      severity,
    };
  });

  const formattedUnmatchedVendors = unmatchedVendors.map((vendor) => {
    const { aggregatedTransactions, ...otherVendorProps } = vendor;
    const vendorTransactionProps = extractAndSumVendorTransactions(
      aggregatedTransactions
    );

    const { id: status, severity } = vendorStatuses.unmatched;

    return {
      ...otherVendorProps,
      ...vendorTransactionProps,
      vendorScore: 0,
      status,
      severity,
    };
  });

  return [...formattedMatchedVendors, ...formattedUnmatchedVendors];
};

export const buildVendorAlerts = (displayVendors) => {
  const uncategorizedVendors = displayVendors.filter(
    ({ status }) => status === "uncategorized"
  );
  const noTransactionsVendors = displayVendors.filter(
    ({ status }) => status === "noTransactions"
  );

  const uncategorizedVendorsAlert = uncategorizedVendors.length
    ? [
        {
          title: `You have ${
            uncategorizedVendors.length
          } vendor${letterSBoolean(uncategorizedVendors)} that need${
            uncategorizedVendors.length === 1 ? "s" : ""
          } to be categorized`,
          subtitle:
            "Scroll down to the vendors table to view and edit the vendors that need more data input from you.",
        },
      ]
    : [];

  const noTransactionsAlert = noTransactionsVendors.length
    ? [
        {
          title: `You have ${
            noTransactionsVendors.length
          } vendor${letterSBoolean(
            noTransactionsVendors
          )} that don't have any transactions`,
          subtitle:
            "Scroll down to the vendors table to view and edit the vendors that need more data input from you.",
        },
      ]
    : [];

  return [...uncategorizedVendorsAlert, ...noTransactionsAlert];
};

export const archiveVendorTransactions = async (vendorId) => {
  const { id: companyId } = getAccountCollectionAndId();

  return await fetchOurApi({
    path: "/transactions/archive-vendor-transactions",
    method: "POST",
    data: {
      companyId,
      vendorId,
    },
  });
};

export const updateVendorTransactions = async ({
  tonsCo2ePerDollar,
  emissionCategory,
  name: vendorName,
  scopeThreeCategory,
  naicsCode,
  naicsTitle,
  vendorIdAssociatedWithTransactions,
  vendorIdToUpdateInTransactions,
}) => {
  const { id: companyId } = getAccountCollectionAndId();

  return await fetchOurApi({
    path: "/transactions/update-vendor-transactions",
    method: "POST",
    data: {
      companyId,
      tonsCo2ePerDollar,
      emissionCategory,
      vendorName,
      vendorIdAssociatedWithTransactions,
      vendorIdToUpdateInTransactions,
      scopeThreeCategory,
      naicsCode,
      naicsTitle,
    },
  });
};

export const useOnVendorSave = (callback = () => {}) => {
  const { updateCollectionDoc } = useCachedFirebaseCrud();

  const [saveLoading, setSaveLoading] = useState(false);

  const onVendorSave = async (vendor) => {
    const {
      emissionCategory,
      name: vendorName,
      scopeThreeCategory,
      naicsCode,
      naicsTitle,
      status,
      vendorIdAssociatedWithTransactions,
      vendorIdToUpdateInTransactions,
    } = vendor;

    const collection = status === "unmatched" ? "vendors" : "vendor-companies";
    const updateVendorId =
      status === "unmatched"
        ? vendorIdAssociatedWithTransactions
        : vendorIdToUpdateInTransactions;

    const vendorData = {
      emissionCategory,
      scopeThreeCategory,
      naicsTitle,
      naicsCode,
    };

    const vendorPropsUpdateObj =
      status === "unmatched" ? vendorData : { vendorData };

    setSaveLoading(true);
    updateVendorTransactions({
      ...vendor,
      vendorIdAssociatedWithTransactions,
      vendorIdToUpdateInTransactions,
    });
    updateCollectionDoc(collection, updateVendorId, {
      name: vendorName,
      ...vendorPropsUpdateObj,
    });

    return callback();
  };

  return { saveLoading, onVendorSave, setSaveLoading };
};

export const useVendorSurveyData = (collection) => {
  const [user, userLoading] = useAuth();
  const { uid: userId } = user || {};
  const { invitationId, companyId } = useParams();

  return useApiData({
    path: "/vendors/fetch-vendor-data",
    method: "POST",
    data: {
      companyId,
      invitationId,
      userId,
      collection,
    },
    isLoading: userLoading,
  });
};
