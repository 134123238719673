import { useState, useContext } from "react";
import { useFeatureIsOn } from "@growthbook/growthbook-react";

import { Button, Grid, Typography, Alert, useTheme } from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faCheck, faQuestion } from "@fortawesome/free-solid-svg-icons";
import {
  faCarBuilding,
  faLaptopHouse,
  faBriefcase,
  faCoffee,
  faChairOffice,
  faStoreAltSlash,
  faExclamationTriangle,
} from "@fortawesome/pro-regular-svg-icons";
import CheckIcon from "@mui/icons-material/Check";

import { Autocomplete, TextField } from "@aclymatepackages/atoms";
import { numbersRegExpTest } from "@aclymatepackages/reg-exp";
import { arrayToString } from "@aclymatepackages/converters";
import { formatDate, letterSBoolean } from "@aclymatepackages/formatters";
import { utilitiesProviders } from "@aclymatepackages/lists";
import { incorrectNameCheck } from "@aclymatepackages/other-helpers";
import { YesNoQuestion } from "@aclymatepackages/modules";
import { findEmissionsSinceLastMonth } from "@aclymatepackages/date-helpers";
import { buildCompanyOfficesEmissions } from "@aclymatepackages/calcs/recurring";
import {
  findOfficeCurrentChangeData,
  convertOfficeElectricCarbonIntensityToTonsPerMwh,
} from "@aclymatepackages/calcs/helpers";
import { calcOfficeUtilitiesPerSqFootage } from "@aclymatepackages/calcs/recurring/defaultCalcs";

import AnalyticsFeatureIcon from "../../components/atoms/premium-analytics/AnalyticsFeatureIcon";
import PlacesAutocomplete from "../../components/inputs/autocomplete/PlacesAutocomplete";
import DatePicker from "../../components/atoms/mui/DatePicker";
import FacilityTypeSelect from "../../components/inputs/offices/FacilityTypeSelect";
import ElectricRenewablesYesNoQuestion from "../../components/inputs/offices/ElectricRenewablesYesNoQuestion";
import ElectricRenewablesPercentageInput from "../../components/inputs/offices/ElectricRenewablesPercentageInput";
import ElectricIntensityYesNoQuestion from "../../components/inputs/offices/ElectricIntensityYesNoQuestion";
import ElectricIntensityAndUnitsInput from "../../components/inputs/offices/ElectricIntensityAndUnitsInput";
import NewOfficeUtilityForm from "../../components/inputs/offices/NewOfficeUtilityForm";
import Link from "../../components/atoms/mui/Link";

import { useCreateMeterTasks } from "./utilitiesApi";
import {
  useSubscriptionType,
  useCompletedOnboardingSteps,
  useStarterTierSubscriptionFlags,
} from "../hooks/companyData";
import useAccountingData from "../hooks/accountingData";

import { PlatformLayoutContext } from "../contexts/platformLayout";
import { fetchOurApi, fetchZipCodeEGrid } from "../utils/apiCalls";
import { analyticsTrack } from "../analytics";
import {
  useAccountData,
  useCachedFirebaseCrud,
  useCachedDisplayData,
  useCollectionDataListener,
} from "../firebase";
import { findGoogleAddressStringDetails } from "../utils/geography";
import useEmissionsContext from "../contexts/emissions";
import { splitAggregatedTransactionsBreakdownSubcategories } from "./emissions";

export const electricRenewablesPercentageError = (
  electricRenewablesPercentage
) =>
  electricRenewablesPercentage &&
  (!numbersRegExpTest(electricRenewablesPercentage) ||
    electricRenewablesPercentage > 100);

const electricCarbonIntensitySaveEnabled = (
  isElectricCarbonIntensityGiven,
  electricCarbonIntensity,
  electricCarbonIntensityUnits
) =>
  isElectricCarbonIntensityGiven
    ? !electricCarbonIntensityError(electricCarbonIntensity) &&
      electricCarbonIntensityUnits
    : true;

const electricRenewablesPercentageSaveEnabled = (
  isElectricRenewablesPercentageGiven,
  electricRenewablesPercentage
) =>
  isElectricRenewablesPercentageGiven
    ? !electricRenewablesPercentageError(electricRenewablesPercentage)
    : true;

export const electricCarbonIntensityError = (electricCarbonIntensity) =>
  electricCarbonIntensity &&
  (!numbersRegExpTest(electricCarbonIntensity) ||
    electricCarbonIntensity >= 1000000);

export const electricRenewablesAndIntensitySaveEnabled = ({
  isElectricRenewablesPercentageGiven,
  isElectricCarbonIntensityGiven,
  electricRenewablesPercentage,
  electricCarbonIntensity,
  electricCarbonIntensityUnits,
}) =>
  electricRenewablesPercentageSaveEnabled(
    isElectricRenewablesPercentageGiven,
    electricRenewablesPercentage
  ) &&
  (electricRenewablesPercentage === "100" ||
    electricCarbonIntensitySaveEnabled(
      isElectricCarbonIntensityGiven,
      electricCarbonIntensity,
      electricCarbonIntensityUnits
    ));

const useOfficeCategories = (iconSize = "sm") => {
  const { palette } = useTheme();

  return [
    {
      category: "companyOffice",
      type: "companyOffice",
      name: "Company Office",
      color: palette.offices.main,
      icon: <FontAwesomeIcon icon={faCarBuilding} size={iconSize} />,
      tooltip: "Add a company office for this employee.",
      waypoint: "/images/platform/icons/offices-main.png",
    },
    {
      category: "homeOffice",
      type: "homeOffice",
      name: "Home Office",
      color: palette.offices.light,
      icon: <FontAwesomeIcon icon={faLaptopHouse} size={iconSize} />,
      tooltip: "Add a home office for this employee.",
      waypoint: "/images/platform/icons/offices-light.png",
    },
    {
      category: "otherSpace",
      name: "Other Space",
      icon: <FontAwesomeIcon icon={faBriefcase} size={iconSize} />,
      color: palette.offices.dark,
      tooltip: "Add another space for this employee.",
      waypoint: "/images/platform/icons/offices-dark.png",
      types: [
        {
          name: "Coffee Shop",
          type: "coffeeShop",
          icon: <FontAwesomeIcon icon={faCoffee} size={iconSize} />,
        },
        {
          type: "coworking",
          name: "Coworking Space",
          icon: (
            <FontAwesomeIcon icon={faChairOffice} size={iconSize || "sm"} />
          ),
        },
      ],
    },
  ];
};

export const useAllOfficeTypes = (iconSize) => {
  const officeCategories = useOfficeCategories(iconSize);

  return officeCategories.flatMap(({ types, ...otherCategoryProps }) => {
    if (!types) {
      return otherCategoryProps;
    }

    return types.map((typeObj) => ({ ...otherCategoryProps, ...typeObj }));
  });
};

export const useOfficeTypes = (params = {}) => {
  const { type, iconSize } = params;
  const officeCategories = useOfficeCategories(iconSize);

  const officeTypes = officeCategories.flatMap(({ types, ...otherProps }) => {
    if (!types) {
      return { ...otherProps };
    }
    return types.map((type) => ({ ...otherProps, ...type }));
  });

  if (type) {
    return officeTypes.find((office) => office.type === type);
  }

  return officeTypes;
};

export const useOfficeStatuses = (endDate) => {
  const { palette } = useTheme();

  return {
    closed: {
      id: "closed",
      icon: faStoreAltSlash,
      tooltip: `This office hasn't been in use since ${formatDate(endDate)}`,
      emissionsDetailsTooltip: `This office hasn't been in use since ${formatDate(
        endDate
      )} and is considered closed.`,
      color: palette.error.main,
      name: "Closed offices",
      severity: 0,
    },
    missingEmissions: {
      id: "missingEmissions",
      icon: faExclamationTriangle,
      tooltip:
        "We're using default values for some of this office's emissions.",
      emissionsDetailsTooltip:
        "We're using default values for some of this office's emissions. You can fix this by adding more emissions for this office below.",
      color: palette.mileage.main,
      name: "Offices with default emissions",
      severity: 3,
    },
    noEmployees: {
      id: "noEmployees",
      icon: faQuestion,
      tooltip:
        "This office doesn't have any current commute employees. Make sure it's still in use.",
      emissionsDetailsTooltip:
        "This office doesn't have any current commute employees. Make sure it's still in use by adding or editing employees to commute here.",
      color: palette.mileage.main,
      name: "Offices with no employees",
      severity: 2,
    },
    active: {
      id: "active",
      icon: faCheck,
      tooltip: "This office is currently in active use by employees",
      emissionsDetailsTooltip:
        "This office is currently in active use by employees",
      color: palette.secondary.main,
      severity: 1,
      name: "Active Offices",
    },
  };
};

export const findUtilitiesConfig = (utilities) => {
  const { utilitiesInLease, combinedUtility } = utilities || {};

  if (utilitiesInLease) {
    return "utilitiesInLease";
  }
  if (combinedUtility) {
    return "combinedUtilities";
  }
  return "separateUtilities";
};

export const useOfficesWithCurrentChanges = () => {
  const [offices, officesLoading] = useCollectionDataListener("offices");

  const formattedOffices = offices.length
    ? offices.map((office) => {
        const { currentUtilities } = office;
        const utilitiesConfig = findUtilitiesConfig(currentUtilities);

        return { ...findOfficeCurrentChangeData(office), utilitiesConfig };
      })
    : [];

  return [formattedOffices, officesLoading];
};

export const setOfficeAddress = (setEndpoint, setName) => async (place) => {
  if (place) {
    const { place_id, description } = place;
    const { zipCode, state, country, name, coordinates } =
      await findGoogleAddressStringDetails(place_id);

    if (setName && name) {
      setEndpoint("name")(name);
    }

    return setEndpoint("address")({
      description: description,
      zipCode,
      state,
      country,
      coordinates,
      placeId: place_id,
    });
  }
  return setEndpoint("address")(null);
};

export const useBuildNewOfficeInputFormRows = () => {
  const utilityApiFeatures = useFeatureIsOn("utilityapi-features");
  const [{ isPaid }] = useSubscriptionType("saas");
  const [{ name: companyName, startDate: companyStartDate }] = useAccountData();
  const [{ earliestNewEmissionDate }] = useAccountingData();
  const { isStarterTier } = useStarterTierSubscriptionFlags();

  return ({ office, editOffice, index }) => {
    const {
      name,
      address,
      isPayingOfficeUtilities,
      doesOfficeHaveGasUtility,
      isGasSameAsElectricUtility,
      sqFootage,
      isGasInIncludedUtilities,
      electricUtility,
      gasUtility = null,
      combinedUtility,
      isUtilityIntegrating,
      linkedElectricUtility,
      linkedGasUtility,
      linkedCombinedUtility,
      isElectricRenewablesPercentageGiven,
      electricRenewablesPercentage,
      isElectricCarbonIntensityGiven,
      electricCarbonIntensity,
      electricCarbonIntensityUnits,
      hasNoStartDate,
      startDate,
      hasNoEndDate,
      endDate,
      facilityType,
    } = office;

    const addNewOfficeAddress = (field) => (value) => editOffice(field, value);

    const officeNameInputRow = {
      label: "What do you call this office?",
      value: name && !incorrectNameCheck(name),
      input: (
        <TextField
          label="Office Name"
          value={name}
          setValue={(value) => editOffice("name", value)}
          error={name && incorrectNameCheck(name)}
          helperText={
            name &&
            incorrectNameCheck(name) &&
            "You need to have at least one non-numeric character in the office name and the name cannot have more than 50 characters"
          }
        />
      ),
    };

    const officeAddressInputRow = {
      label: "What's the address?",
      value: address,
      input: (
        <PlacesAutocomplete
          label="What's the address?"
          place={address || null}
          editPlace={setOfficeAddress(addNewOfficeAddress)}
          size="small"
          id={`NewOffice_${index}_Address`}
        />
      ),
    };

    const isPayingOfficeUtilitiesInputRow = {
      label: "Do you pay utilities for this office?",
      value: isPayingOfficeUtilities !== undefined,
      input: (
        <YesNoQuestion
          question="Do you pay utilities for this office?"
          value={isPayingOfficeUtilities}
          setValue={(isPaying) =>
            editOffice("isPayingOfficeUtilities", isPaying)
          }
        />
      ),
    };

    const handleSetIsGasSameAsElectricUtility = (
      isGasSameAsElectricUtility
    ) => {
      editOffice("isGasSameAsElectricUtility", isGasSameAsElectricUtility);
      editOffice("electricUtility", gasUtility);
      return editOffice(
        "combinedUtility",
        isGasSameAsElectricUtility ? gasUtility : null
      );
    };

    const filteredUtilitiesProviders = utilitiesProviders.filter(
      ({ states, utilityId }) =>
        states.includes(address?.state) ||
        (process.env.REACT_APP_ENVIRONMENT !== "production" &&
          utilityId === "DEMO")
    );

    const doesOfficeHaveGasUtilityInputRow = {
      label: "Do you have a gas utility for this office?",
      value:
        (!doesOfficeHaveGasUtility && electricUtility) ||
        isGasSameAsElectricUtility ||
        (electricUtility && gasUtility),
      input: (
        <Grid container spacing={2} direction="column">
          <Grid item>
            <YesNoQuestion
              question="Do you have a gas utility for this office?"
              value={doesOfficeHaveGasUtility}
              setValue={(value) =>
                editOffice("doesOfficeHaveGasUtility", value)
              }
            />
          </Grid>
          {doesOfficeHaveGasUtility && (
            <>
              <Grid item>
                <Typography variant="body2">
                  Please select your gas utility from this list, or type in the
                  name on your bills if you don't see your utility.
                </Typography>
              </Grid>
              <Grid item>
                <Autocomplete
                  label="Select gas utility"
                  id="gas-utility-provider-autocomplete"
                  availableOptions={filteredUtilitiesProviders}
                  value={gasUtility}
                  setValue={(selectedUtility) =>
                    editOffice(
                      "gasUtility",
                      typeof selectedUtility === "string"
                        ? { name: selectedUtility }
                        : selectedUtility
                    )
                  }
                  freeSolo
                  autoSelect
                />
              </Grid>
              {gasUtility && (
                <Grid item>
                  <YesNoQuestion
                    question="Is this the same utility you get electricity from?"
                    value={isGasSameAsElectricUtility}
                    setValue={(value) =>
                      handleSetIsGasSameAsElectricUtility(value)
                    }
                  />
                </Grid>
              )}
            </>
          )}
          {(doesOfficeHaveGasUtility === false ||
            isGasSameAsElectricUtility === false) && (
            <>
              <Grid item>
                <Typography variant="body2">
                  Please select your electric utility from this list, or type in
                  the name on your bills if you don't see your utility.
                </Typography>
              </Grid>
              <Grid item>
                <Autocomplete
                  label="Select electric utility"
                  id="electric-utility-provider-autocomplete"
                  availableOptions={filteredUtilitiesProviders}
                  value={electricUtility}
                  setValue={(selectedUtility) =>
                    editOffice(
                      "electricUtility",
                      typeof selectedUtility === "string"
                        ? { name: selectedUtility }
                        : selectedUtility
                    )
                  }
                  freeSolo
                  autoSelect
                />
              </Grid>
            </>
          )}
        </Grid>
      ),
    };

    const selectedElectricUtilityIntegration = utilitiesProviders.find(
      ({ name: providerName }) => providerName === electricUtility?.name
    );
    const selectedGasUtilityIntegration = utilitiesProviders.find(
      ({ name: providerName }) => providerName === gasUtility?.name
    );
    const selectedIntegrationUtilities = [
      selectedElectricUtilityIntegration && {
        ...selectedElectricUtilityIntegration,
        type: "electric",
      },
      selectedGasUtilityIntegration && {
        ...selectedGasUtilityIntegration,
        type: "gas",
      },
    ].filter((integration) => integration);

    const utilityIntegrationQuestion =
      electricUtility && gasUtility && !combinedUtility
        ? `It looks like we can automate collection of your electric bills through our integration with ${electricUtility?.name} and your gas bills through our integration with ${gasUtility?.name}. Would you like to integrate?`
        : `It looks like we can automate collection of your utility bills through our integration with ${
            electricUtility?.name || gasUtility?.name
          }. Would you like to proceed?`;

    const isUtilityIntegratingComplete = selectedIntegrationUtilities.reduce(
      (isComplete, { type }) =>
        type === "electric"
          ? isComplete && (linkedElectricUtility || linkedCombinedUtility)
          : isComplete && (linkedGasUtility || linkedCombinedUtility),
      true
    );

    const isUtilitiesIntegratable =
      selectedIntegrationUtilities.length &&
      isPayingOfficeUtilities &&
      utilityApiFeatures &&
      !isStarterTier;

    const buildMatchedUtilityInputRow =
      isUtilitiesIntegratable && isPaid
        ? [
            {
              label: "Utility provider integration",
              value:
                isUtilityIntegrating === false ||
                (isUtilityIntegrating && isUtilityIntegratingComplete),
              input: (
                <Grid container spacing={2} direction="column">
                  <Grid item>
                    <YesNoQuestion
                      question={utilityIntegrationQuestion}
                      value={isUtilityIntegrating}
                      setValue={(value) =>
                        editOffice("isUtilityIntegrating", value)
                      }
                    />
                  </Grid>
                  {isUtilityIntegrating && (
                    <Grid item>
                      <NewOfficeUtilityForm
                        utilityProvidersList={selectedIntegrationUtilities}
                        editOffice={editOffice}
                      />
                    </Grid>
                  )}
                </Grid>
              ),
            },
          ]
        : [];

    const officeSquareFootageInputRow = {
      label: "What's the square footage?",
      value: numbersRegExpTest(sqFootage) && sqFootage < 1000000,
      input: (
        <TextField
          required
          label="What's the square footage?"
          value={sqFootage}
          setValue={(value) => editOffice("sqFootage", value)}
          error={
            sqFootage && (!numbersRegExpTest(sqFootage) || sqFootage >= 1000000)
          }
          helperText={
            sqFootage &&
            (!numbersRegExpTest(sqFootage) || sqFootage >= 1000000) &&
            "You need to input a number for the square footage and/or the square footage needs to be less than 1,000,000"
          }
          id={`NewOffice_${index}_SqFootage`}
        />
      ),
    };

    const officeFacilityTypeInputRow = {
      value: facilityType?.value,
      input: (
        <FacilityTypeSelect
          facilityType={facilityType}
          editFacilityType={(value) => editOffice("facilityType", value)}
          label="What type of facility is this?"
        />
      ),
    };

    const buildOfficeStartDateCheckBoxInputRow = !earliestNewEmissionDate
      ? [
          {
            input: (
              <Grid container direction="column" spacing={1}>
                {!isPaid && !!isUtilitiesIntegratable && (
                  <Grid item>
                    <Alert severity="success">
                      Once you become a subscriber you'll be able to link your
                      utilities to automate calculating emissions from your
                      utility bills.
                    </Alert>
                  </Grid>
                )}
                <Grid item>
                  <YesNoQuestion
                    question={`${companyName} started at this office before ${formatDate(
                      companyStartDate
                    )}`}
                    value={hasNoStartDate}
                    setValue={(value) => editOffice("hasNoStartDate", value)}
                  />
                </Grid>
              </Grid>
            ),
            value: hasNoStartDate !== undefined,
          },
        ]
      : [];

    const buildOfficeMoveInDateInputRow = !hasNoStartDate
      ? [
          {
            input: (
              <DatePicker
                label="When did you move into this office?"
                darkTheme
                date={startDate || earliestNewEmissionDate || companyStartDate}
                minDate={earliestNewEmissionDate || companyStartDate}
                maxDate={new Date()}
                editDate={(date) => editOffice("startDate", new Date(date))}
              />
            ),
            value: companyStartDate,
          },
        ]
      : [];

    const buildOfficeStillWorksAtOfficeCheckBoxInputRow = [
      {
        input: (
          <YesNoQuestion
            question={`${companyName} still works at this office`}
            value={hasNoEndDate}
            setValue={(value) => editOffice("hasNoEndDate", value)}
          />
        ),
        value: hasNoEndDate !== undefined,
      },
    ];

    const buildOfficeMoveOutDateInputRow = !hasNoEndDate
      ? [
          {
            input: (
              <DatePicker
                label="When did you move out of this office?"
                darkTheme={true}
                date={endDate}
                minDate={startDate}
                maxDate={new Date()}
                editDate={(date) => editOffice("endDate", new Date(date))}
              />
            ),
            value: endDate,
          },
        ]
      : [];

    const isGasInIncludedUtilitiesInputRow = {
      input: (
        <YesNoQuestion
          question="Is gas included in your utilities?"
          value={isGasInIncludedUtilities}
          setValue={(value) => editOffice("isGasInIncludedUtilities", value)}
        />
      ),
      value: isGasInIncludedUtilities !== undefined,
    };

    const officeElectricRenewablesPercentCheckBoxInputRow = {
      input: (
        <ElectricRenewablesYesNoQuestion
          questionText={
            "Are you getting part of your electric utilities from renewable energy sources?"
          }
          isElectricRenewablesPercentageGiven={
            isElectricRenewablesPercentageGiven
          }
          editIsElectricRenewablesPercentageGiven={(value) =>
            editOffice("isElectricRenewablesPercentageGiven", value)
          }
        />
      ),
      value: isElectricRenewablesPercentageGiven !== undefined,
    };

    const buildOfficeElectricRenewablesPercentInputRow =
      isElectricRenewablesPercentageGiven
        ? [
            {
              label:
                "Percentage of electric utilities that come from renewables",
              input: (
                <ElectricRenewablesPercentageInput
                  electricRenewablesPercentage={electricRenewablesPercentage}
                  editElectricRenewablesPercentage={(value) =>
                    editOffice("electricRenewablesPercentage", value)
                  }
                  index={index}
                />
              ),
              value:
                electricRenewablesPercentage &&
                !electricRenewablesPercentageError(
                  electricRenewablesPercentage
                ),
            },
          ]
        : [];

    const buildOfficeElectricCarbonIntensityCheckBoxInputRow =
      electricRenewablesPercentage !== "100"
        ? [
            {
              input: (
                <ElectricIntensityYesNoQuestion
                  questionText={
                    "Do you know the carbon intensity of your electric utilities that don't come from renewables?"
                  }
                  isElectricCarbonIntensityGiven={
                    isElectricCarbonIntensityGiven
                  }
                  editIsElectricCarbonIntensityGiven={(value) =>
                    editOffice("isElectricCarbonIntensityGiven", value)
                  }
                />
              ),
              value: isElectricCarbonIntensityGiven !== undefined,
            },
          ]
        : [];

    const buildOfficeElectricCarbonIntensityInputRow =
      isElectricCarbonIntensityGiven
        ? [
            {
              label: "Carbon intensity of electricity utilities",
              input: (
                <Grid container direction="row" spacing={2}>
                  <ElectricIntensityAndUnitsInput
                    electricCarbonIntensity={electricCarbonIntensity}
                    editElectricCarbonIntensity={(value) =>
                      editOffice("electricCarbonIntensity", value)
                    }
                    electricCarbonIntensityUnits={electricCarbonIntensityUnits}
                    editElectricCarbonIntensityUnits={(value) =>
                      editOffice("electricCarbonIntensityUnits", value)
                    }
                    index={index}
                  />
                </Grid>
              ),
              value:
                electricCarbonIntensity &&
                !electricCarbonIntensityError(electricCarbonIntensity) &&
                electricCarbonIntensityUnits,
            },
          ]
        : [];

    const buildIsPayingOfficeUtilitiesFlow = () => {
      if (!isPayingOfficeUtilities) {
        return [
          isGasInIncludedUtilitiesInputRow,
          officeElectricRenewablesPercentCheckBoxInputRow,
          ...buildOfficeElectricRenewablesPercentInputRow,
          ...buildOfficeElectricCarbonIntensityCheckBoxInputRow,
          ...buildOfficeElectricCarbonIntensityInputRow,
        ];
      }

      return [doesOfficeHaveGasUtilityInputRow, ...buildMatchedUtilityInputRow];
    };

    const newOfficeForm = [
      officeNameInputRow,
      officeAddressInputRow,
      officeSquareFootageInputRow,
      officeFacilityTypeInputRow,
      isPayingOfficeUtilitiesInputRow,
      ...buildIsPayingOfficeUtilitiesFlow(),
      ...buildOfficeStartDateCheckBoxInputRow,
      ...buildOfficeMoveInDateInputRow,
      ...buildOfficeStillWorksAtOfficeCheckBoxInputRow,
      ...buildOfficeMoveOutDateInputRow,
    ];

    return newOfficeForm;
  };
};

export const useNewOfficesSubmit = () => {
  const { updateCollectionDoc, newCollectionDoc, updateAccountData } =
    useCachedFirebaseCrud();
  const [{ primaryOffice, isRemote }] = useAccountData();
  const createMeterTasks = useCreateMeterTasks();

  const editCompanyData = async (newOfficeIds, newOffices) => {
    if (!primaryOffice) {
      const [firstOfficeId] = newOfficeIds;
      const [{ address, name }] = newOffices;

      const eGrid = await fetchZipCodeEGrid(address);

      return updateAccountData({
        "geography.address": address,
        "geography.eGrid": eGrid,
        isRemote: false,
        primaryOffice: { name, id: firstOfficeId },
      });
    }

    if (isRemote) {
      return updateAccountData({ isRemote: false });
    }

    return { newOffices, newOfficeIds };
  };

  const activateNewMeters = async ({
    linkedElectricUtility = {},
    linkedGasUtility = {},
    linkedCombinedUtility = {},
    name,
  }) => {
    const { pairedMeters: combinedPairedMeters = [] } = linkedCombinedUtility;
    const { pairedMeters: electricPairedMeters = [] } = linkedElectricUtility;
    const { pairedMeters: gasPairedMeters = [] } = linkedGasUtility;
    const newMeters = [
      ...combinedPairedMeters,
      ...electricPairedMeters,
      ...gasPairedMeters,
    ];

    if (newMeters.length) {
      const newMetersIds = newMeters.map(({ meterId }) => meterId);
      const formattedMetersList = newMeters.map((meter) => ({
        pairedOffice: { name },
        ...meter,
      }));

      await createMeterTasks(formattedMetersList);

      return fetchOurApi({
        path: "/integrations/utilityApi/activate-meters",
        method: "POST",
        data: { metersList: newMetersIds },
        callback: (res) => res,
      });
    }

    return null;
  };

  return async (newOffices) => {
    analyticsTrack("Offices Added", { numberOfOffices: newOffices.length });

    const buildNewOfficeObj = async (office) => {
      const {
        name,
        sqFootage,
        address,
        facilityType,
        electricUtility: formElectricUtility,
        gasUtility: formGasUtility,
        electricRenewablesPercentage,
        electricCarbonIntensity,
        electricCarbonIntensityUnits,
        isPayingOfficeUtilities,
        doesOfficeHaveGasUtility,
        isGasSameAsElectricUtility,
        isUtilityIntegrating,
        isGasInIncludedUtilities,
        linkedElectricUtility,
        linkedGasUtility,
        linkedElectricUtilityId,
        linkedGasUtilityId,
        startDate,
        endDate,
      } = office;

      const eGrid = await fetchZipCodeEGrid(address);

      const buildLinkedUtilitiesProps = () => {
        if (!isUtilityIntegrating) {
          return {};
        }

        if (isGasSameAsElectricUtility) {
          const { pairedMeters: gasPairedMeters } = linkedGasUtility;
          const { pairedMeters: electricPairedMeters } = linkedElectricUtility;

          const linkedCombinedUtility = {
            ...linkedElectricUtility,
            pairedMeters: [...gasPairedMeters, ...electricPairedMeters],
          };

          return {
            linkedCombinedUtilityId: linkedElectricUtilityId,
            linkedCombinedUtility,
          };
        }

        const linkedElectricUtilityObj = linkedElectricUtility
          ? { linkedElectricUtilityId, linkedElectricUtility }
          : {};

        const linkedGasUtilityObj = linkedGasUtility
          ? { linkedGasUtilityId, linkedGasUtility }
          : {};

        return { ...linkedElectricUtilityObj, ...linkedGasUtilityObj };
      };

      const buildOfficeUtilitiesObj = () => {
        if (!isPayingOfficeUtilities) {
          return {
            isGasInIncludedUtilities,
            utilitiesInLease: true,
            eGrid,
          };
        }

        const baseReturnObj = {
          eGrid,
          utilitiesInLease: false,
          doesOfficeHaveGasUtility,
        };

        const getNameFromUtilityObj = (utility) =>
          utility?.name || utility || "None";

        const electricUtility = getNameFromUtilityObj(formElectricUtility);
        const gasUtility = getNameFromUtilityObj(formGasUtility);

        if (!doesOfficeHaveGasUtility) {
          return {
            ...baseReturnObj,
            electricUtility,
          };
        }

        if (isGasSameAsElectricUtility) {
          return {
            ...baseReturnObj,
            combinedUtility: gasUtility,
          };
        }

        return { ...baseReturnObj, gasUtility, electricUtility };
      };

      return {
        name,
        sqFootage: Number(sqFootage),
        address,
        archived: false,
        type: "companyOffice",
        facilityType,
        startDate,
        endDate,
        electricRenewablesPercentage,
        electricCarbonIntensity,
        electricCarbonIntensityUnits,
        utilities: buildOfficeUtilitiesObj(),
        ...buildLinkedUtilitiesProps(),
      };
    };

    const newOfficeIds = await Promise.all(
      newOffices.map(async (office) => {
        const { id } = office;
        const newOfficeObj = await buildNewOfficeObj(office);

        if (id) {
          await updateCollectionDoc("offices", id, newOfficeObj);
          return id;
        }

        await activateNewMeters(newOfficeObj);
        return await newCollectionDoc("offices", newOfficeObj);
      })
    );

    return editCompanyData(newOfficeIds, newOffices);
  };
};

export const useFormatDisplayOffices = () => {
  const { convertCarbonUnits } = useContext(PlatformLayoutContext);
  const officeStatuses = useOfficeStatuses();

  return ({ employees, offices, startDate, emissionsMonths, transactions }) => {
    const buildReturnObj = ({
      monthlyElectricCarbonTons = 0,
      monthlyGasCarbonTons = 0,
      monthlyElectricRenewablesReplacementTons = 0,
      isGasInIncludedUtilities,
    }) => {
      const monthlyGasCarbonVolume = convertCarbonUnits(
        isGasInIncludedUtilities === false ? 0 : monthlyGasCarbonTons
      );
      const monthlyElectricCarbonVolume = convertCarbonUnits(
        monthlyElectricCarbonTons
      );
      const monthlyElectricRenewablesReplacementVolume = convertCarbonUnits(
        monthlyElectricRenewablesReplacementTons
      );

      return {
        monthlyGasCarbonVolume,
        monthlyElectricCarbonVolume,
        monthlyElectricRenewablesReplacementVolume,
        totalMonthlyCarbonVolume:
          monthlyElectricCarbonVolume + monthlyGasCarbonVolume,
      };
    };

    const findOfficeTotalMonthlyCarbonVolumes = ({
      utilities,
      emissions,
      sqFootage,
      state,
      country,
      electricCarbonIntensity,
      electricCarbonIntensityUnits,
      electricRenewablesPercentage: officeElectricRenewablesPercentage,
      type,
    }) => {
      const { utilitiesInLease, eGrid, isGasInIncludedUtilities } =
        utilities || {};

      if (utilitiesInLease) {
        const utilities = calcOfficeUtilitiesPerSqFootage({
          type,
          sqFootage,
          state,
          country,
          date: new Date(),
          eGrid,
          electricCarbonIntensityTonsPerMwh:
            convertOfficeElectricCarbonIntensityToTonsPerMwh(
              electricCarbonIntensity,
              electricCarbonIntensityUnits
            ),
          electricRenewablesPercentage: officeElectricRenewablesPercentage,
          isGasInIncludedUtilities,
        });

        return buildReturnObj(utilities);
      }

      const emissionsSinceLastMonth = findEmissionsSinceLastMonth(emissions);

      if (!emissionsSinceLastMonth.length) {
        return {
          monthlyElectricCarbonVolume: 0,
          monthlyGasCarbonVolume: 0,
          totalMonthlyCarbonVolume: 0,
        };
      }

      if (emissionsSinceLastMonth.length > 1) {
        const emissionsSums = emissionsSinceLastMonth.reduce(
          (
            {
              monthlyElectricCarbonTons,
              monthlyGasCarbonTons,
              monthlyElectricRenewablesReplacementTons,
            },
            emission
          ) => {
            const {
              subcategory,
              gasCarbonTons,
              electricCarbonTons,
              tonsCo2e,
              electricRenewablesReplacementTons = 0,
            } = emission;

            if (subcategory === "electricity") {
              return {
                monthlyElectricCarbonTons: monthlyElectricCarbonTons + tonsCo2e,
                monthlyGasCarbonTons,
                monthlyElectricRenewablesReplacementTons:
                  monthlyElectricRenewablesReplacementTons +
                  electricRenewablesReplacementTons,
              };
            }

            if (subcategory === "gas") {
              return {
                monthlyGasCarbonTons: monthlyGasCarbonTons + tonsCo2e,
                monthlyElectricCarbonTons,
              };
            }

            return {
              monthlyGasCarbonTons: monthlyGasCarbonTons + gasCarbonTons,
              monthlyElectricCarbonTons:
                monthlyElectricCarbonTons + electricCarbonTons,
              monthlyElectricRenewablesReplacementTons:
                monthlyElectricRenewablesReplacementTons +
                electricRenewablesReplacementTons,
            };
          },
          {
            monthlyElectricCarbonTons: 0,
            monthlyGasCarbonTons: 0,
            monthlyElectricRenewablesReplacementTons: 0,
          }
        );

        return buildReturnObj({ ...emissionsSums, ...utilities });
      }

      const [
        {
          electricRenewablesPercentage,
          gasCarbonTons,
          electricCarbonTons,
          electricRenewablesReplacementTons,
          subcategory,
          tonsCo2e,
        },
      ] = emissionsSinceLastMonth;

      if (electricRenewablesPercentage === 100) {
        return buildReturnObj({
          monthlyElectricRenewablesReplacementTons:
            electricRenewablesReplacementTons,
        });
      }

      if (subcategory === "electricity") {
        return buildReturnObj({
          monthlyGasCarbonVolume: 0,
          monthlyElectricCarbonTons: tonsCo2e,
          monthlyElectricRenewablesReplacementTons:
            electricRenewablesReplacementTons,
        });
      }

      if (subcategory === "gas") {
        return buildReturnObj({
          monthlyGasCarbonTons: tonsCo2e,
          monthlyElectricCarbonTons: 0,
        });
      }

      return buildReturnObj({
        monthlyGasCarbonTons: gasCarbonTons,
        monthlyElectricCarbonTons: electricCarbonTons,
        monthlyElectricRenewablesReplacementTons:
          electricRenewablesReplacementTons,
      });
    };

    const currentCompanyOfficeEndpoints = employees.flatMap(
      ({ id, name, commuteSchedules }) => {
        if (!Array.isArray(commuteSchedules)) {
          return [];
        }

        const [{ commuteEndpoints: currentEndpoints }] = commuteSchedules;
        return currentEndpoints
          .filter(({ type }) => type === "companyOffice")
          .map((endpoint) => ({
            ...endpoint,
            employeeId: id,
            employeeName: name,
          }));
      }
    );

    const findOfficeStatus = ({
      endDate,
      currentUtilities,
      emissions,
      currentCommuteEmployees,
    }) => {
      if (endDate) {
        return "closed";
      }

      const { utilitiesInLease } = currentUtilities || {};

      const doesOfficeHaveDefaultEmissions = emissions.some(
        ({ source }) => source === "default-utilities"
      );

      if (!utilitiesInLease && doesOfficeHaveDefaultEmissions) {
        return "missingEmissions";
      }

      if (!currentCommuteEmployees.length) {
        return "noEmployees";
      }

      return "active";
    };

    return offices.map((office) => {
      const {
        id,
        facilityType,
        endDate,
        currentUtilities,
        sqFootage,
        address,
        electricCarbonIntensity,
        electricCarbonIntensityUnits,
        electricRenewablesPercentage,
      } = office;

      const { utilitiesInLease, isGasInIncludedUtilities } =
        currentUtilities || {};

      const currentCommuteEmployees = currentCompanyOfficeEndpoints
        ? currentCompanyOfficeEndpoints
            .filter((endpoint) => endpoint.id === id)
            .map(({ daysPerWk, employeeId, employeeName }) => ({
              daysPerWk,
              id: employeeId,
              name: employeeName,
            }))
        : [];

      const emissionsSubcategoryFields = isGasInIncludedUtilities
        ? { subcategory: "utilities", name: "Utilities" }
        : {
            subcategory: "electricity",
            name: "Electricity",
          };

      const officeScheduleEmissions = utilitiesInLease
        ? buildCompanyOfficesEmissions({
            companyStartDate: startDate,
            offices: [office],
            emissionsMonths,
            emissionsSubcategoryFields,
          })
        : [];

      const officeTaggedEmissions = transactions.filter(
        ({ office }) => office?.id === id
      );

      const emissions = [...officeTaggedEmissions, ...officeScheduleEmissions];

      const status = findOfficeStatus({
        endDate,
        currentUtilities,
        emissions,
        currentCommuteEmployees,
      });

      const { severity } = officeStatuses[status];

      const monthlyCarbonVolumes = findOfficeTotalMonthlyCarbonVolumes({
        utilities: currentUtilities,
        emissions,
        sqFootage,
        electricCarbonIntensity,
        electricCarbonIntensityUnits,
        electricRenewablesPercentage,
        type: facilityType?.value,
        ...address,
      });

      return {
        ...office,
        currentUtilities,
        emissions,
        facilityType: facilityType || { label: "Office" },
        status,
        severity,
        currentCommuteEmployees,
        ...monthlyCarbonVolumes,
      };
    });
  };
};

export const useOfficesData = () => {
  const utilityApiFeatures = useFeatureIsOn("utilityapi-features");
  const officeStatuses = useOfficeStatuses();
  const { isStarterTier } = useStarterTierSubscriptionFlags();

  const {
    emissionsMonths,
    emissionsLoading,
    separateUtilitiesOfficesDefaultEmissions,
  } = useEmissionsContext();
  const { convertCarbonUnits } = useContext(PlatformLayoutContext);
  const [{ isPaid }] = useSubscriptionType();

  const [{ startDate: companyStartDate }, companyDataLoading] =
    useAccountData();
  const [{ officesComplete }, onboardingDataLoading] =
    useCompletedOnboardingSteps();
  const [integratedUtilities, integratedUtilitiesLoading] =
    useCollectionDataListener("integrations", [["type", "==", "utilitiesApi"]]);
  const [confirmedEmployees, employeesLoading] = useCachedDisplayData(
    "employees",
    [["status", "==", "confirmed"]]
  );
  const [offices, officesLoading] = useOfficesWithCurrentChanges();

  const [
    selectedUtilityProviderFromBanner,
    setSelectedUtilityProviderFromBanner,
  ] = useState();

  const componentLoading =
    emissionsLoading ||
    companyDataLoading ||
    officesLoading ||
    employeesLoading ||
    integratedUtilitiesLoading ||
    onboardingDataLoading;

  const formatDisplayOffices = () => {
    if (componentLoading) {
      return [];
    }

    const buildReturnObj = ({
      monthlyElectricCarbonTons = 0,
      monthlyGasCarbonTons = 0,
      monthlyElectricRenewablesReplacementTons = 0,
      isGasInIncludedUtilities,
    }) => {
      const monthlyGasCarbonVolume = convertCarbonUnits(
        isGasInIncludedUtilities === false ? 0 : monthlyGasCarbonTons
      );
      const monthlyElectricCarbonVolume = convertCarbonUnits(
        monthlyElectricCarbonTons
      );
      const monthlyElectricRenewablesReplacementVolume = convertCarbonUnits(
        monthlyElectricRenewablesReplacementTons
      );

      return {
        monthlyGasCarbonVolume,
        monthlyElectricCarbonVolume,
        monthlyElectricRenewablesReplacementVolume,
        totalMonthlyCarbonVolume:
          monthlyElectricCarbonVolume + monthlyGasCarbonVolume,
      };
    };

    const findOfficeTotalMonthlyCarbonVolumes = ({
      utilities,
      emissions,
      sqFootage,
      state,
      country,
      electricCarbonIntensity,
      electricCarbonIntensityUnits,
      electricRenewablesPercentage: officeElectricRenewablesPercentage,
      type,
    }) => {
      const { utilitiesInLease, eGrid, isGasInIncludedUtilities } =
        utilities || {};

      if (utilitiesInLease) {
        const utilities = calcOfficeUtilitiesPerSqFootage({
          type,
          sqFootage,
          state,
          country,
          date: new Date(),
          eGrid,
          electricCarbonIntensityTonsPerMwh:
            convertOfficeElectricCarbonIntensityToTonsPerMwh(
              electricCarbonIntensity,
              electricCarbonIntensityUnits
            ),
          electricRenewablesPercentage: officeElectricRenewablesPercentage,
          isGasInIncludedUtilities,
        });

        return buildReturnObj(utilities);
      }

      const emissionsSinceLastMonth = findEmissionsSinceLastMonth(emissions);

      if (!emissionsSinceLastMonth.length) {
        return {
          monthlyElectricCarbonVolume: 0,
          monthlyGasCarbonVolume: 0,
          totalMonthlyCarbonVolume: 0,
        };
      }

      if (emissionsSinceLastMonth.length > 1) {
        const emissionsSums = emissionsSinceLastMonth.reduce(
          (
            {
              monthlyElectricCarbonTons,
              monthlyGasCarbonTons,
              monthlyElectricRenewablesReplacementTons,
            },
            emission
          ) => {
            const {
              subcategory,
              gasCarbonTons,
              electricCarbonTons,
              tonsCo2e,
              electricRenewablesReplacementTons = 0,
            } = emission;

            if (subcategory === "electricity") {
              return {
                monthlyElectricCarbonTons: monthlyElectricCarbonTons + tonsCo2e,
                monthlyGasCarbonTons,
                monthlyElectricRenewablesReplacementTons:
                  monthlyElectricRenewablesReplacementTons +
                  electricRenewablesReplacementTons,
              };
            }

            if (subcategory === "gas") {
              return {
                monthlyGasCarbonTons: monthlyGasCarbonTons + tonsCo2e,
                monthlyElectricCarbonTons,
              };
            }

            return {
              monthlyGasCarbonTons: monthlyGasCarbonTons + gasCarbonTons,
              monthlyElectricCarbonTons:
                monthlyElectricCarbonTons + electricCarbonTons,
              monthlyElectricRenewablesReplacementTons:
                monthlyElectricRenewablesReplacementTons +
                electricRenewablesReplacementTons,
            };
          },
          {
            monthlyElectricCarbonTons: 0,
            monthlyGasCarbonTons: 0,
            monthlyElectricRenewablesReplacementTons: 0,
          }
        );

        return buildReturnObj({ ...emissionsSums, ...utilities });
      }

      const [
        {
          electricRenewablesPercentage,
          gasCarbonTons,
          electricCarbonTons,
          electricRenewablesReplacementTons,
          subcategory,
          tonsCo2e,
        },
      ] = emissionsSinceLastMonth;

      if (electricRenewablesPercentage === 100) {
        return buildReturnObj({
          monthlyElectricRenewablesReplacementTons:
            electricRenewablesReplacementTons,
        });
      }

      if (subcategory === "electricity") {
        return buildReturnObj({
          monthlyGasCarbonVolume: 0,
          monthlyElectricCarbonTons: tonsCo2e,
          monthlyElectricRenewablesReplacementTons:
            electricRenewablesReplacementTons,
        });
      }

      if (subcategory === "gas") {
        return buildReturnObj({
          monthlyGasCarbonTons: tonsCo2e,
          monthlyElectricCarbonTons: 0,
        });
      }

      return buildReturnObj({
        monthlyGasCarbonTons: gasCarbonTons,
        monthlyElectricCarbonTons: electricCarbonTons,
        monthlyElectricRenewablesReplacementTons:
          electricRenewablesReplacementTons,
      });
    };

    const currentCompanyOfficeEndpoints = confirmedEmployees.flatMap(
      ({ id, name, commuteSchedules }) => {
        if (!Array.isArray(commuteSchedules)) {
          return [];
        }

        const [{ commuteEndpoints: currentEndpoints }] = commuteSchedules;
        return currentEndpoints
          .filter(({ type }) => type === "companyOffice")
          .map((endpoint) => ({
            ...endpoint,
            employeeId: id,
            employeeName: name,
          }));
      }
    );

    const findOfficeStatus = ({
      endDate,
      currentUtilities,
      emissions,
      currentCommuteEmployees,
    }) => {
      if (endDate) {
        return "closed";
      }

      const { utilitiesInLease } = currentUtilities || {};

      const doesOfficeHaveDefaultEmissions = emissions.some(
        ({ source }) => source === "default-utilities"
      );

      if (!utilitiesInLease && doesOfficeHaveDefaultEmissions) {
        return "missingEmissions";
      }

      if (!currentCommuteEmployees.length) {
        return "noEmployees";
      }

      return "active";
    };

    return offices.map((office) => {
      const {
        id,
        facilityType,
        endDate,
        currentUtilities,
        sqFootage,
        address,
        electricCarbonIntensity,
        electricCarbonIntensityUnits,
        electricRenewablesPercentage,
        aggregatedTransactions = [],
      } = office;

      const { utilitiesInLease, isGasInIncludedUtilities } =
        currentUtilities || {};

      const currentCommuteEmployees = currentCompanyOfficeEndpoints
        ? currentCompanyOfficeEndpoints
            .filter((endpoint) => endpoint.id === id)
            .map(({ daysPerWk, employeeId, employeeName }) => ({
              daysPerWk,
              id: employeeId,
              name: employeeName,
            }))
        : [];

      const emissionsSubcategoryFields = isGasInIncludedUtilities
        ? { subcategory: "utilities", name: "Utilities" }
        : {
            subcategory: "electricity",
            name: "Electricity",
          };

      const officeScheduleEmissions = utilitiesInLease
        ? buildCompanyOfficesEmissions({
            companyStartDate,
            offices: [office],
            emissionsMonths,
            emissionsSubcategoryFields,
          })
        : [];

      const officeTaggedTransactions =
        splitAggregatedTransactionsBreakdownSubcategories(
          aggregatedTransactions
        );
      const officeDefaultEmissions =
        separateUtilitiesOfficesDefaultEmissions.filter(
          ({ taggedOfficeId }) => taggedOfficeId === id
        );

      const emissions = [
        ...officeTaggedTransactions,
        ...officeScheduleEmissions,
        ...officeDefaultEmissions,
      ];

      const status = findOfficeStatus({
        endDate,
        currentUtilities,
        emissions,
        currentCommuteEmployees,
      });

      const { severity } = officeStatuses[status];

      const monthlyCarbonVolumes = findOfficeTotalMonthlyCarbonVolumes({
        utilities: currentUtilities,
        emissions,
        sqFootage,
        electricCarbonIntensity,
        electricCarbonIntensityUnits,
        electricRenewablesPercentage,
        type: facilityType?.value,
        ...address,
      });

      return {
        ...office,
        currentUtilities,
        emissions,
        facilityType: facilityType || { label: "Office" },
        status,
        severity,
        currentCommuteEmployees,
        ...monthlyCarbonVolumes,
      };
    });
  };

  const displayOffices = formatDisplayOffices();
  const highSeverityOffices = displayOffices.filter(
    ({ severity }) => severity > 1
  );

  const buildHighSeverityOfficesAlert = () => {
    if (componentLoading || !officesComplete || !highSeverityOffices.length) {
      return [];
    }
    return [
      {
        title: `You have ${highSeverityOffices.length} office${letterSBoolean(
          highSeverityOffices
        )} that need${
          highSeverityOffices.length > 1 ? "" : "s"
        } your attention.`,
        subtitle:
          "Scroll down to the offices table to view and edit the offices that need more data input from you.",
      },
    ];
  };

  const buildOfficeUtilitiesAlerts = () => {
    const availableUtilitiesToIntegrateWith = utilitiesProviders.filter(
      ({ utilityId, states }) => {
        const officesInCoverageArea = offices.filter(
          ({
            utilities: { utilitiesInLease },
            address: { state: officeState },
          }) => states.includes(officeState) && !utilitiesInLease
        );

        return (
          !!officesInCoverageArea.length &&
          officesInCoverageArea.every(
            ({
              linkedCombinedUtilityId,
              linkedElectricUtilityId,
              linkedGasUtilityId,
            }) =>
              linkedCombinedUtilityId !== utilityId &&
              linkedElectricUtilityId !== utilityId &&
              linkedGasUtilityId !== utilityId
          )
        );
      }
    );
    const utilitiesToDisplay = availableUtilitiesToIntegrateWith.slice(0, 3);

    if (
      integratedUtilitiesLoading ||
      integratedUtilities.length ||
      !utilityApiFeatures
    ) {
      return [];
    }

    if (isStarterTier && utilitiesToDisplay.length) {
      const utilityProviderNames = utilitiesToDisplay.map(({ name }) => name);
      const isOneUtilityProvider = utilityProviderNames.length === 1;

      return [
        {
          title: `Connect to ${arrayToString(utilityProviderNames)}`,
          subtitle: `When you connect ${
            isOneUtilityProvider ? "this provider" : "these providers"
          }, Aclymate can completely automate your utility emissions, no more manual entry! Upgrade your subscription to unlock this feature`,
          action: (
            <Link href="/platform/company/settings/products">
              <Button color="secondary" variant="contained">
                Upgrade
              </Button>
            </Link>
          ),
          severity: "success",
          icon: <AnalyticsFeatureIcon />,
          isCollapsible: true,
        },
      ];
    }

    return utilitiesToDisplay.map((utilityProvider) => ({
      title: `Connect to ${utilityProvider?.name}`,
      subtitle: `It looks like there are offices that fall under the utility provider ${utilityProvider?.name}. When you connect this provider, Aclymate can completely automate your utility emissions, no more manual entry!`,
      action: (
        <Button
          color="secondary"
          variant="contained"
          onClick={() => setSelectedUtilityProviderFromBanner(utilityProvider)}
          disabled={!isPaid}
        >
          Connect utility provider
        </Button>
      ),
      severity: "success",
      icon: isPaid ? <CheckIcon fontSize="large" /> : <AnalyticsFeatureIcon />,
      isCollapsible: true,
    }));
  };

  const officeAlerts = [
    ...buildHighSeverityOfficesAlert(),
    ...buildOfficeUtilitiesAlerts(),
  ];

  return {
    offices: displayOffices,
    selectedUtilityProviderFromBanner,
    setSelectedUtilityProviderFromBanner,
    officeAlerts,
    officesLoading: componentLoading,
  };
};
