import React, { useState, useContext } from "react";
import dayjs from "dayjs";
import { Promise as bluebird } from "bluebird";

import {
  Grid,
  StyledEngineProvider,
  Typography,
  ThemeProvider,
} from "@mui/material";

import { formatDecimal } from "@aclymatepackages/formatters";
import { editObjectData } from "@aclymatepackages/array-immutability-helpers";
import { DEFAULT_TONS_CO2E_PER_MILE } from "@aclymatepackages/constants";
import { mergeDarkTheme } from "@aclymatepackages/themes";

import useCsvUploader from "./csvUploader";
import CircularProgressWithLabel from "../atoms/loading/CircularProgressWithLabel";
import EmployeesInput from "../inputs/tags/EmployeesInput";
import VehicleInput from "../inputs/tags/VehicleInput";

import {
  doesFieldExist,
  createErrorObj,
} from "../../helpers/components/inputs";
import { PlatformLayoutContext } from "../../helpers/contexts/platformLayout";
import { fetchOurApi, fetchPlaceId } from "../../helpers/utils/apiCalls";
import { analyticsTrack } from "../../helpers/analytics";
import { useAccountData } from "../../helpers/firebase";
import { buildFormattedTagsObjs } from "../../helpers/components/tags";
import { useSharedFormLoading } from "../../helpers/components/inputs";
import { onNewTransaction } from "../../helpers/components/emissions";

const fieldOptions = [
  { label: "Date", value: "date" },
  { label: "Description", value: "description" },
  { label: "Start Address", value: "from" },
  { label: "End Address", value: "to" },
  { label: "Distance (mi.)", value: "mileage" },
  { label: "Start/Stop Mileage", value: "startStop" },
  { label: "Vehicle", value: "vehicle" },
];

const requiredFields = [
  "Date",
  "To & From, or Distance (mi.), or Start/Stop Mileage",
];

const knownFields = [
  { header: "date", field: "date" },
  { header: "trip purpose", field: "description" },
  { header: "description", field: "description" },
  { header: "start", field: "from" },
  { header: "start address", field: "from" },
  { header: "from", field: "from" },
  { header: "to", field: "to" },
  { header: "end", field: "to" },
  { header: "end address", field: "to" },
  { header: "mileage", field: "mileage" },
  { header: "distance", field: "mileage" },
  { header: "start/stop mileage", field: "startStop" },
  { header: "start/stop", field: "startStop" },
  { header: "vehicle", field: "vehicle" },
];

const fieldValidator = (headers) => {
  if (!doesFieldExist(headers, "date")) {
    return createErrorObj('Your CSV must include a "date" field');
  }

  if (
    !(doesFieldExist(headers, "from") && doesFieldExist(headers, "to")) &&
    !doesFieldExist(headers, "mileage") &&
    !doesFieldExist(headers, "startStop")
  ) {
    return createErrorObj(
      'Your CSV must include "To" and "From" fields, a "Distance" field or a "Start/Stop Mileage" field'
    );
  }
  return { success: true };
};

const csvTripsCarbonData = async (trips, knownVehicle) => {
  return await Promise.all(
    trips.map(async (trip) => {
      const { mileage, startStop, from, to } = trip;
      const { tonsCo2ePerMile } = knownVehicle || {
        tonsCo2ePerMile: DEFAULT_TONS_CO2E_PER_MILE,
      };

      if (mileage) {
        return {
          ...trip,
          tonsCo2e: Number(mileage) * tonsCo2ePerMile,
        };
      }

      if (startStop) {
        const [start, stop] = startStop.replace(" ", "").split(/[^0-9.]/);
        const estimatedMileage = Math.abs(Number(stop) - Number(start));

        return {
          ...trip,
          mileage: estimatedMileage,
          tonsCo2e: estimatedMileage * tonsCo2ePerMile,
        };
      }

      const { totalMileage } = await fetchOurApi({
        path: "/calcs/cars/directions-mileage",
        method: "POST",
        data: { to, from },
        callback: (res) => res,
      });

      return {
        ...trip,
        mileage: totalMileage,
        tonsCo2e: totalMileage * tonsCo2ePerMile,
      };
    })
  );
};

const processCompleteTrips = async (
  completeTrips,
  editCsv,
  knownVehicle = null
) => {
  editCsv("loaded", false);
  if (!completeTrips.length) {
    return editCsv("loaded", true);
  }

  const tripCarbonData = await csvTripsCarbonData(completeTrips, knownVehicle);

  const totalMileage = tripCarbonData.reduce(
    (acc, curr) => acc + Number(curr.mileage),
    0
  );

  const totalCarbonTons = tripCarbonData.reduce(
    (acc, curr) => acc + curr.tonsCo2e,
    0
  );

  if (knownVehicle) {
    editCsv("vehicle", true);
  }
  editCsv("completeTrips", tripCarbonData);
  editCsv("tripsCount", tripCarbonData.length);
  editCsv("totalMileage", Number(totalMileage));
  editCsv("totalCarbonTons", totalCarbonTons);
  return editCsv("loaded", true);
};

const CsvTaggingMenu = ({
  csv: { employees = [], vehicle = {}, totalMileage, completeTrips },
  editCsv,
}) => {
  const [companyData] = useAccountData();
  const { geography } = companyData || {};
  const { address } = geography || {};

  const [popperAnchorEl, setPopperAnchorEl] = useState(null);
  const [activePopper, setActivePopper] = useState("");

  const editVehicle = async (vehicle) => {
    editCsv("vehicle", vehicle);
    const { tonsCo2ePerMile, make, model, year, fuelType } = vehicle;

    const fetchTonsCo2e = async (mileage) =>
      await fetchOurApi({
        path: "/calcs/cars/total-mileage-carbon",
        method: "POST",
        data: {
          vehicle: { make, model, year, fuelType },
          mileage: Number(mileage),
          zip: address.zipCode,
        },
        callback: ({ tonsCo2e }) => tonsCo2e,
      });

    const totalCarbonTons = tonsCo2ePerMile
      ? tonsCo2ePerMile * totalMileage
      : await fetchTonsCo2e(totalMileage);

    const updatedTrips = await Promise.all(
      completeTrips.map(async (trip) => {
        const totalTripCarbon = tonsCo2ePerMile
          ? trip.mileage * tonsCo2ePerMile
          : await fetchTonsCo2e(trip.mileage);

        return {
          ...trip,
          tonsCo2e: totalTripCarbon,
        };
      })
    );
    editCsv("completeTrips", updatedTrips);
    return editCsv("totalCarbonTons", totalCarbonTons);
  };

  const removeEmployee = ({ id }) => {
    const filteredEmployees = employees.filter(
      (employee) => employee.id !== id
    );

    return editCsv("employees", filteredEmployees);
  };

  const propsObj = {
    popperAnchorEl,
    setPopperAnchorEl,
    activePopper,
    setActivePopper,
    disableInputCardResizing: true,
  };

  const inputOptions = [
    <EmployeesInput
      employees={employees}
      setEmployees={(employees) => editCsv("employees", employees)}
      removeEmployee={removeEmployee}
      title="Tag employees for these trips"
      position="left"
      {...propsObj}
    />,
    <VehicleInput
      vehicle={vehicle}
      setVehicle={editVehicle}
      position="right"
      title="Tag a vehicle for these trips"
      {...propsObj}
    />,
  ];

  return (
    <Grid container direction="row" justifyContent="space-evenly" spacing={2}>
      {inputOptions.map((input, idx) => (
        <Grid item xs={6} key={`tag-input-${idx}`}>
          {input}
        </Grid>
      ))}
    </Grid>
  );
};

const useProcessMileageTrips = ({
  setOpen,
  setTripsResponseCount,
  setTotalTripsCount,
  fileInfo,
}) => {
  const { setFormLoading } = useSharedFormLoading();
  const { activateSnackbar } = useContext(PlatformLayoutContext);

  const fetchToAndFromPlaceIds = async ({ to, from }) => {
    const [toPlaceId, fromPlaceId] = await Promise.all([
      fetchPlaceId(to),
      fetchPlaceId(from),
    ]);

    return { toPlaceId, fromPlaceId };
  };

  const createToAndFromObj = async (to, from) => {
    if (to && from) {
      const { toPlaceId, fromPlaceId } = await fetchToAndFromPlaceIds({
        to,
        from,
      });

      return {
        to: {
          description: to,
          placeId: toPlaceId,
        },
        from: {
          description: from,
          placeId: fromPlaceId,
        },
      };
    }

    return {};
  };

  const preProcessTrips = async ({ employees, vehicle, completeTrips }) =>
    await Promise.all(
      completeTrips.map(async (trip) => {
        const { to, from } = trip;

        const toAndFromObj = await createToAndFromObj(to, from);

        return {
          ...trip,
          ...buildFormattedTagsObjs({ employees, vehicle }),
          ...toAndFromObj,
          roundTrip: false,
          source: "csv-upload",
          csvName: fileInfo?.name,
          status: "confirmed",
          subcategory: "mileage",
          vendor: `${dayjs().format("MM/DD/YYYY")} Other Emissions`,
          archived: false,
          isAggregated: false,
        };
      })
    );

  const batchTrips = (trips) => {
    const BATCH_SIZE = 500;

    return Array.from(
      {
        length: Math.ceil(trips.length / BATCH_SIZE),
      },
      (_, batchIndex) =>
        trips.slice(
          batchIndex * BATCH_SIZE,
          batchIndex * BATCH_SIZE + BATCH_SIZE
        )
    );
  };

  const saveBatchOfTrips = (batchOfTrips) => {
    Promise.all(batchOfTrips.map(onNewTransaction));
    return setTripsResponseCount(
      (currentTripsCount) => currentTripsCount + batchOfTrips.length
    );
  };

  return async (csv) => {
    setFormLoading(true);
    const formattedTrips = await preProcessTrips(csv);
    setTotalTripsCount(formattedTrips.length);
    const batchedTrips = batchTrips(formattedTrips);

    analyticsTrack("Mileage Trips Loaded", {
      numberOfTransactions: formattedTrips.length,
    });

    await bluebird.map(batchedTrips, saveBatchOfTrips);

    const snackbarMessage = `${formattedTrips.length} mileage trips were added to your account.`;
    activateSnackbar({ message: snackbarMessage, alert: "success" });
    setFormLoading(false);
    setOpen(false);
  };
};

const MileageReviewSummary = ({
  setOpen,
  loadedCsv,
  setLoadedCsv,
  fileInfo,
}) => {
  const {
    tripsCount = 0,
    totalMileage = 0,
    totalCarbonTons = 0,
    rejectedTrips,
    loaded,
  } = loadedCsv || {};

  const { activateSnackbar, convertCarbonUnits, displayUnitLabel } = useContext(
    PlatformLayoutContext
  );

  const csvSummaryData = [
    `File Name: ${fileInfo?.name}`,
    `Number of Trips: ${formatDecimal(tripsCount)}`,
    `Total Miles: ${formatDecimal(totalMileage)}`,
    `Total ${displayUnitLabel} CO2: ${formatDecimal(
      convertCarbonUnits(totalCarbonTons)
    )}`,
  ];

  const buildMileageReviewSummary = () => {
    if (!loaded) {
      return (
        <Grid
          container
          justifyContent="center"
          spacing={3}
          direction="column"
          alignItems="center"
        >
          <Grid item xs={12} spacing={2}>
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={mergeDarkTheme}>
                <CircularProgressWithLabel />
              </ThemeProvider>
            </StyledEngineProvider>
          </Grid>
        </Grid>
      );
    }

    if (!tripsCount && rejectedTrips) {
      const snackbarMessage =
        "All of your mileage trips were rejected because they happened before your company start date.";
      activateSnackbar({ message: snackbarMessage, alert: "error" });
      setOpen(false);

      return <> </>;
    }

    if (!tripsCount && !rejectedTrips) {
      const snackbarMessage =
        "The CSV you uploaded contains no valid mileage trips.";
      activateSnackbar({ message: snackbarMessage, alert: "warning" });
      setOpen(false);

      return <> </>;
    }

    return (
      <Grid
        container
        justifyContent="center"
        spacing={3}
        direction="column"
        alignItems="center"
      >
        <Grid item xs={12} spacing={2}>
          <Typography>
            This is the summary of the mileage CSV you uploaded:
          </Typography>
        </Grid>
        {csvSummaryData.map((summaryData) => (
          <Grid item xs={12} spacing={2}>
            <Typography style={{ fontWeight: 600 }}>{summaryData}</Typography>
          </Grid>
        ))}
        <Grid item xs={12} spacing={2}>
          <Typography align="center">
            You can use the input boxes below to tag specific employees and/or a
            vehicle to these trips.
          </Typography>
        </Grid>
        <Grid container item>
          <CsvTaggingMenu
            csv={loadedCsv || {}}
            editCsv={(field, value) =>
              editObjectData(setLoadedCsv, field, value)
            }
          />
        </Grid>
      </Grid>
    );
  };

  return buildMileageReviewSummary();
};

const useMileageReview = ({
  fileInfo,
  loadedCsv,
  setLoadedCsv,
  setOpen,
  setTripsResponseCount,
  setTotalTripsCount,
}) => {
  const processMileageTrips = useProcessMileageTrips({
    setOpen,
    setTripsResponseCount,
    setTotalTripsCount,
    fileInfo,
  });
  const { loaded } = loadedCsv || {};

  const onNextStep = () => processMileageTrips(loadedCsv);

  return [
    {
      label: "Mileage CSV Summary",
      input: (
        <MileageReviewSummary
          setOpen={setOpen}
          loadedCsv={loadedCsv}
          setLoadedCsv={setLoadedCsv}
          fileInfo={fileInfo}
        />
      ),
      onAdvanceForm: onNextStep,
      buttonDisabled: !loaded,
      buttonText: "Submit",
    },
  ];
};

const useMileageCsvUploader = ({ open, setOpen }) => {
  const [{ startDate }] = useAccountData();

  const [fileInfo, setFileInfo] = useState({});
  const [loadedCsv, setLoadedCsv] = useState({});
  const [matchedHeaders, setMatchedHeaders] = useState([]);
  const [tripsResponseCount, setTripsResponseCount] = useState(0);
  const [totalTripsCount, setTotalTripsCount] = useState(0);

  const matchedVehicleField = matchedHeaders.some(
    (field) => field.field === "vehicle"
  );

  const findCompleteTrips = (dataObjects) => {
    const fixableTrips = dataObjects.filter(
      ({ date, startStop, mileage, to, from }) =>
        date && (startStop || mileage || to || from)
    );

    const completeTripBoolean = ({
      date,
      mileage,
      startStop,
      from,
      to,
      vehicle,
    }) =>
      date &&
      (mileage || startStop || (from && to)) &&
      (matchedVehicleField ? vehicle : true);

    const completeTrips = fixableTrips
      .filter((trip) => completeTripBoolean(trip))
      .map((trip) => ({ ...trip, date: new Date(trip.date) }));

    return completeTrips;
  };

  const processCsvData = ({ data: parsedCsvData, fileName }) => {
    const completeTrips = findCompleteTrips(parsedCsvData);

    const isDateBeforeStartDate = (date) =>
      dayjs(new Date(date)).isBefore(dayjs(startDate));

    const notTooOldTrips = startDate
      ? completeTrips.filter(({ date }) => !isDateBeforeStartDate(date))
      : completeTrips;
    const rejectedTrips = startDate
      ? completeTrips.filter(({ date }) => isDateBeforeStartDate(date))
      : [];

    setLoadedCsv({ loaded: false, name: fileName, rejectedTrips });

    const editLoadedCsv = (field, value) =>
      editObjectData(setLoadedCsv, field, value);

    return processCompleteTrips(notTooOldTrips, editLoadedCsv);
  };

  const mileageCsvUploaderSteps = useCsvUploader({
    knownFields,
    fieldOptions,
    open,
    setOpen,
    fileInfo,
    setFileInfo,
    matchedHeaders,
    setMatchedHeaders,
    processData: processCsvData,
    fieldValidator,
    docType: "mileage",
    firstStepText:
      "We'll automatically calculate your carbon emissions for the drives that you upload",
    requiredFields,
  });

  const mileageReviewSteps = useMileageReview({
    fileInfo,
    loadedCsv,
    setLoadedCsv,
    matchedVehicleField,
    setOpen,
    setTripsResponseCount,
    setTotalTripsCount,
  });

  const completeMileageForm = [
    ...mileageCsvUploaderSteps,
    ...mileageReviewSteps,
  ];

  return [completeMileageForm, (tripsResponseCount / totalTripsCount) * 100];
};
export default useMileageCsvUploader;
