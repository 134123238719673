import React, { useState, useEffect } from "react";
import dayjs from "dayjs";

import {
  Avatar,
  IconButton,
  Box,
  Grid,
  Typography,
  ButtonBase,
  useTheme,
  StyledEngineProvider,
  ThemeProvider,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { DefaultPaper, CategoriesAvatar } from "@aclymatepackages/atoms";
import { formatDate, ucFirstLetters } from "@aclymatepackages/formatters";
import { editObjectData } from "@aclymatepackages/array-immutability-helpers";
import { mergeDarkTheme } from "@aclymatepackages/themes";
import { subcategories } from "@aclymatepackages/subcategories";

import TransactionDetailsBlock from "../../platform-pages/dashboard/TransactionDetailsBlock";
import Backdrop from "../../atoms/mui/Backdrop";

import useInputType from "../../../helpers/components/display-lists/inputTypes";
import { buildFormattedTagsObjs } from "../../../helpers/components/tags";
import { onNewTransaction } from "../../../helpers/components/emissions";

const SubcategoryButtonCard = ({
  category: { name, subcategory },
  inputSubcategory,
  setInputSubcategory,
}) => {
  const { palette } = useTheme();

  const [hover, setHover] = useState(false);

  const [title, subtitle] = name.split("- ");

  return (
    <Grid item>
      <ButtonBase
        onClick={() => setInputSubcategory(subcategory)}
        style={{ minWidth: "220px", textAlign: "left" }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        id={`manualInput-${subcategory}-option`}
      >
        <DefaultPaper
          style={{
            width: "100%",
            backgroundColor:
              (hover || inputSubcategory === subcategory) &&
              palette.employees.grayscale,
          }}
        >
          <Grid container spacing={1} alignItems="center" wrap="nowrap">
            <Grid item>
              <CategoriesAvatar subcategory={subcategory} />
            </Grid>
            <Grid item>
              <Typography variant="body2" align="left" color="textSecondary">
                {title}
              </Typography>
              <Typography variant="h6" align="left">
                {subtitle}
              </Typography>
            </Grid>
          </Grid>
        </DefaultPaper>
      </ButtonBase>
    </Grid>
  );
};

const SubcategorySelector = ({
  subcategory,
  editSubcategory,
  availableSubcategories,
  emissionsAddName,
}) => {
  const { color, primaryIcon: PrimaryIcon } = useInputType("transactions");

  const inputSubcategories = subcategories.filter(({ input }) => input);

  return (
    <Grid
      container
      direction="column"
      spacing={4}
      alignItems="center"
      wrap="nowrap"
      justifyContent="center"
    >
      <Grid item>
        <Avatar
          style={{
            backgroundColor: color,
            height: 100,
            width: 100,
          }}
        >
          <PrimaryIcon style={{ fontSize: "4rem" }} />
        </Avatar>
      </Grid>
      <Grid item sm={8}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={mergeDarkTheme}>
            <Typography variant="h3" color="primary" align="center">
              {`Select the category of emission that you'd like to add ${
                emissionsAddName ? `for ${emissionsAddName}` : ""
              }`}
            </Typography>
          </ThemeProvider>
        </StyledEngineProvider>
      </Grid>
      <Grid item>
        <Grid container spacing={2} justifyContent="center">
          {(availableSubcategories || inputSubcategories).map(
            (category, idx) => (
              <SubcategoryButtonCard
                key={`subcategory-selection-card-${idx}`}
                category={category}
                inputSubcategory={subcategory}
                setInputSubcategory={editSubcategory}
              />
            )
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

const ManualTransactionsInput = ({
  setDialogOpen,
  startingTransactionProps = {},
  availableSubcategories,
  emissionsAddName,
}) => {
  const theme = useTheme();

  const [transaction, setTransaction] = useState({
    source: "manual-entry",
    date: new Date(),
    ...startingTransactionProps,
  });
  const { subcategory, date } = transaction;

  const editTransaction = (field) => (value) =>
    editObjectData(setTransaction, field, value);

  useEffect(() => {
    editTransaction("vendor")(
      `${date ? `${formatDate(date)} ` : ""}${ucFirstLetters(
        subcategory || "Other "
      )} Emissions`
    );
  }, [subcategory, date]);

  const onTransactionSave = (transactionDetails) =>
    onNewTransaction({
      date: dayjs(date).toDate(),
      status: "confirmed",
      archived: false,
      ...buildFormattedTagsObjs(transaction),
      ...transaction,
      ...transactionDetails,
    });

  const onSubcategorySelect = (subcategory) => {
    editTransaction("knownVendor")(null);
    return editTransaction("subcategory")(subcategory);
  };

  return (
    <>
      {!subcategory ? (
        <Backdrop
          open
          style={{
            zIndex: 1300,
            color: "white",
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            overflowY: "auto",
          }}
        >
          <Box
            style={{
              height: "100%",
              width: "100%",
              boxSizing: "border-box",
              overflow: "hidden",
            }}
            position="relative"
            display="flex"
            alignItems="center"
            p={5}
          >
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={mergeDarkTheme}>
                <Box
                  position="absolute"
                  top={theme.spacing(2)}
                  right={theme.spacing(2)}
                  zIndex={9999}
                >
                  <IconButton onClick={() => setDialogOpen(false)} size="large">
                    <CloseIcon size="large" />
                  </IconButton>
                </Box>
              </ThemeProvider>
            </StyledEngineProvider>
            <SubcategorySelector
              subcategory={subcategory}
              editSubcategory={onSubcategorySelect}
            />
          </Box>
        </Backdrop>
      ) : (
        <TransactionDetailsBlock
          transaction={transaction}
          editTransaction={editTransaction}
          saveTransaction={onTransactionSave}
          setIsSlideOpen={setDialogOpen}
          parentSubcategory={subcategory}
          slideLeftPanel={
            <SubcategorySelector
              subcategory={subcategory}
              editSubcategory={onSubcategorySelect}
              availableSubcategories={availableSubcategories}
              emissionsAddName={emissionsAddName}
            />
          }
          saveButtonText="Add Transaction"
        />
      )}
    </>
  );
};
export default ManualTransactionsInput;
