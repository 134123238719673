import React, { useState, createContext } from "react";

import { unitTypes } from "../otherHelpers";

export const PlatformLayoutContext = createContext();

export const PlatformLayoutContextProvider = ({ children }) => {
  const LSsideNavOpen = window.localStorage.getItem("sideNavOpen");

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarProps, setSnackbarProps] = useState({});
  const [sideNavOpen, setSideNavOpen] = useState(LSsideNavOpen === "true");

  const [displayUnit, setDisplayUnit] = useState(() => {
    const storedUnit = window.localStorage.getItem("aclymate-display-unit");
    return storedUnit || "lbs";
  });
  const [viewMode, setViewMode] = useState(
    window.localStorage.getItem("aclymate-view-mode") || "company"
  );

  const { short, converter } = unitTypes.find(
    ({ short }) => short === displayUnit
  );

  const activateSnackbar = (props) => {
    setSnackbarProps(props);
    setSnackbarOpen(true);
    setTimeout(() => {
      setSnackbarOpen(false);
    }, 5000);
  };

  return (
    <PlatformLayoutContext.Provider
      value={{
        snackbarOpen,
        setSnackbarOpen,
        snackbarProps,
        activateSnackbar,
        sideNavOpen,
        setSideNavOpen,
        displayUnit,
        setDisplayUnit,
        convertCarbonUnits: converter,
        displayUnitLabel: short,
        viewMode,
        setViewMode,
      }}
    >
      {children}
    </PlatformLayoutContext.Provider>
  );
};
