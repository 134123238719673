import dayjs from "dayjs";

import { useMediaQuery, useTheme } from "@mui/material";

import { tonsToLbs, tonsToKgs } from "@aclymatepackages/converters";

export const getAccountCollectionAndId = (givenAccountId) => {
  const getAccountId = () => {
    if (typeof window !== "undefined") {
      return window.sessionStorage.getItem("accountId");
    }

    if (givenAccountId) {
      return givenAccountId;
    }

    return "";
  };

  const stringAccountId = getAccountId();
  if (!stringAccountId) {
    return {};
  }

  const splitPos = stringAccountId.lastIndexOf("-");
  const collection = stringAccountId.substring(0, splitPos);
  const id = stringAccountId.substring(splitPos + 1);

  return {
    collection,
    id,
  };
};

export const generateRandomPin = () =>
  Math.floor(100000 + Math.random() * 900000);

export const isObjectEmpty = (obj) => {
  if (!obj || typeof obj !== "object") {
    return true;
  }
  return !Object.keys(obj).length;
};

export const generateTempId = () => dayjs().unix() * Math.random();

export const generateSecureId = () => crypto.randomUUID();

export const useLayoutHelpers = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isMedium = useMediaQuery(theme.breakpoints.down("lg"));

  const standardBoxPadding = {
    px: isMobile ? 1 : isMedium ? 2 : 8,
    py: isMobile ? 5 : 8,
  };

  return { theme, isMobile, isMedium, standardBoxPadding };
};

export const sortOptionsAlphabetically = (options = [], key = "name") => {
  if (!options?.length) {
    return [];
  }

  return [...options].sort((a, b) => {
    if (a[key] < b[key]) {
      return -1;
    }
    if (b[key] < a[key]) {
      return 1;
    }
    return 0;
  });
};

export const unitTypes = [
  {
    name: "Pounds",
    short: "lbs",
    converter: (tons) => (tons ? tonsToLbs(tons) : 0),
  },
  {
    name: "Kilograms",
    short: "kgs",
    converter: (tons) => (tons ? tonsToKgs(tons) : 0),
  },
  { name: "Metric Tons", short: "tons", converter: (tons) => tons },
];
