import React, { useContext, useRef, useEffect, useState } from "react";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import advancedFormat from "dayjs/plugin/advancedFormat";

import {
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Area,
  Bar,
  ComposedChart,
  Tooltip as ChartTooltip,
} from "recharts";

import { Box } from "@mui/material";

import { formatDecimal } from "@aclymatepackages/formatters";

import EmissionsCustomTooltip from "./EmissionsCustomTooltip";
import useChartWarningLabels from "./useChartWarningLabels";

import { PlatformLayoutContext } from "../../../helpers/contexts/platformLayout";

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(quarterOfYear);
dayjs.extend(advancedFormat);

const BAR_GAP_PERCENTAGE = "10%";
const X_AXIS_HEIGHT = 20;
const Y_AXIS_WIDTH = 120;

const LabeledEmissionsChart = ({
  dataArray,
  type,
  isPercentageChart,
  displayUnit,
  chartArray,
  aspect = 3.5,
  height,
  showTooltip,
  onBarClick,
  selectedBar,
}) => {
  const { displayUnitLabel } = useContext(PlatformLayoutContext);
  const { warningLabels, labelSetter } = useChartWarningLabels({
    data: dataArray,
    warningField: "warning",
    barSumField: "totalEmissionsSumTons",
  });

  const [chartHeight, setChartHeight] = useState(0);
  const [barWidth, setBarWidth] = useState(0);
  const [gutterWidth, setGutterWidth] = useState(0);
  const chartRef = useRef(null);

  useEffect(() => {
    if (chartRef.current && type === "bar") {
      const boundingBox = chartRef.current.getBoundingClientRect();

      const gapPercentage = parseFloat(BAR_GAP_PERCENTAGE) / 100;
      const chartWidth = boundingBox.width - Y_AXIS_WIDTH;

      const gutterWidth = (chartWidth * gapPercentage) / (dataArray.length - 1);
      const totalGap = gutterWidth * dataArray.length;
      const barWidth = (chartWidth - totalGap) / dataArray.length;

      setBarWidth(barWidth);
      setChartHeight(boundingBox.height);
      setGutterWidth(gutterWidth);
    }
  }, [chartRef, dataArray, type]);

  const ChartElement = type === "bar" ? Bar : Area;

  const unitLabel = isPercentageChart ? "%" : displayUnit || displayUnitLabel;

  const { idx: selectedBarIdx } = selectedBar || {};
  const selectedBarX =
    selectedBar &&
    Y_AXIS_WIDTH + gutterWidth / 2 + selectedBarIdx * (barWidth + gutterWidth);

  return (
    <Box position="relative" sx={{ height }}>
      <ResponsiveContainer aspect={aspect} ref={chartRef}>
        <ComposedChart
          width={500}
          height={300}
          data={dataArray}
          margin={{ left: 0, top: 0 }}
          barCategoryGap={BAR_GAP_PERCENTAGE}
        >
          <XAxis
            dataKey="label"
            interval="preserveStartEnd"
            height={X_AXIS_HEIGHT}
          />
          <YAxis
            tickFormatter={(tick) => `${formatDecimal(tick)} ${unitLabel}`}
            width={Y_AXIS_WIDTH}
          />
          <CartesianGrid strokeDasharray="3 3" />
          {selectedBar && (
            <rect
              x={selectedBarX - gutterWidth / 2}
              y={0}
              width={barWidth + gutterWidth}
              height={chartHeight - X_AXIS_HEIGHT}
              fill="rgba(0, 0, 0, 0.1)"
            />
          )}
          {showTooltip && (
            <ChartTooltip
              wrapperStyle={{ zIndex: 99 }}
              content={
                <EmissionsCustomTooltip
                  categoriesArray={chartArray}
                  displayUnitLabel={displayUnitLabel}
                />
              }
            />
          )}
          {chartArray.map(({ subcategory, color, ...otherProps }) => (
            <ChartElement
              key={`emissions-chart-element-${subcategory}`}
              type="monotone"
              stroke="none"
              fillOpacity={1}
              stackId="a"
              fill={color}
              dataKey={subcategory}
              onClick={
                onBarClick && ((event, index) => onBarClick(event, index))
              }
              isAnimationActive={false}
              {...otherProps}
            >
              {labelSetter}
            </ChartElement>
          ))}
        </ComposedChart>
      </ResponsiveContainer>
      {warningLabels}
    </Box>
  );
};
export default LabeledEmissionsChart;
