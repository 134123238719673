import React, { useState } from "react";

import { editObjectData } from "@aclymatepackages/array-immutability-helpers";

import TransactionDetailsBlock from "./TransactionDetailsBlock";

import { onUpdateTransactions } from "../../../helpers/components/emissions";

const EditRowTransactions = ({
  transaction,
  setIsSlideOpen,
  ...otherProps
}) => {
  const { id } = transaction;

  const [editableTransaction, setEditableTransaction] = useState(transaction);

  const editTransaction = (field) => (value) =>
    editObjectData(setEditableTransaction, field, value);

  const onRejectTransaction = () => {
    setIsSlideOpen(null);

    return onUpdateTransactions([{ id, archived: true }]);
  };

  const onSaveTransaction = async (updateObj) =>
    await onUpdateTransactions([{ id, ...updateObj }]);

  return (
    <TransactionDetailsBlock
      originalTransaction={transaction}
      transaction={editableTransaction}
      editTransaction={editTransaction}
      setIsSlideOpen={setIsSlideOpen}
      onRejectTransaction={onRejectTransaction}
      saveTransaction={onSaveTransaction}
      {...otherProps}
    />
  );
};

export default EditRowTransactions;
