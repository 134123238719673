import React, { useState, useEffect } from "react";

import {
  Container,
  Grid,
  Button,
  Typography,
  IconButton,
  Stepper,
  Step,
  StepLabel,
  Avatar,
  useTheme,
  ThemeProvider,
} from "@mui/material";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineOppositeContent,
} from "@mui/lab";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckIcon from "@mui/icons-material/Check";

import { editObjectData } from "@aclymatepackages/array-immutability-helpers";
import { mergeDarkTheme } from "@aclymatepackages/themes";
import {
  TextField,
  LoadingButton,
  DefaultPaper,
} from "@aclymatepackages/atoms";
import { formatDate } from "@aclymatepackages/formatters";
import { emailRegExpTest } from "@aclymatepackages/reg-exp";

import EditVendorDetails from "./EditVendorDetails";
import EmissionsDetails from "../../EmissionsDetails";
import SettingsObjectDetailsSlider from "../../SettingsObjectDetailsSlider";
import EmissionsDetailsTableAccordion from "../../../../modules/tables/EmissionsDetailsTableAccordion";

import {
  useOnVendorSave,
  findVendorStatus,
  archiveVendorTransactions,
} from "../../../../../helpers/components/vendors";
import {
  useCachedFirebaseCrud,
  useAccountData,
  useAuth,
} from "../../../../../helpers/firebase";
import {
  companyMatchingFunction,
  fetchOurApi,
  newVendorRelationship,
} from "../../../../../helpers/utils/apiCalls";
import { analyticsTrack } from "../../../../../helpers/analytics";

const EditVendorFooter = ({
  editableVendor,
  setSelectedVendor,
  isSaveEnabled,
  matchingCompany,
  matchingCompanies,
}) => {
  const { status: vendorStatus, id: vendorId } = editableVendor;

  const { palette } = useTheme();
  const { updateCollectionDoc } = useCachedFirebaseCrud();

  const { saveLoading, onVendorSave, setSaveLoading } = useOnVendorSave(() =>
    setSelectedVendor(null)
  );

  const onSaveClick = async () => {
    analyticsTrack("Categorized Vendor");
    setSaveLoading(true);
    if (
      (matchingCompanies.length && !matchingCompany) ||
      vendorStatus !== "unmatched"
    ) {
      return onVendorSave(editableVendor);
    }

    const { dbId, pdlId } = matchingCompany || {};

    const { newVendorId } = await newVendorRelationship({
      ...editableVendor,
      dbId,
      pdlId,
    });

    await updateCollectionDoc("vendors", vendorId, { archived: true });

    const { id: status } = findVendorStatus(editableVendor);

    return await onVendorSave({
      ...editableVendor,
      id: dbId || newVendorId,
      status,
    });
  };

  const onDeleteClick = () => {
    updateCollectionDoc(
      vendorStatus === "unmatched" ? "vendors" : "vendor-companies",
      vendorId,
      { archived: true }
    );
    archiveVendorTransactions(vendorId);

    return setSelectedVendor(null);
  };

  const isUnmatched = vendorStatus === "unmatched";

  return (
    <Grid
      container
      justifyContent={!isUnmatched ? "flex-end" : "space-between"}
      alignItems="center"
    >
      {isUnmatched && (
        <Grid item>
          <IconButton onClick={() => onDeleteClick()}>
            <DeleteIcon style={{ color: palette.error.main }} />
          </IconButton>
        </Grid>
      )}
      <Grid item>
        <LoadingButton
          isLoading={saveLoading}
          onClick={onSaveClick}
          variant="contained"
          color="primary"
          label="Save Edits"
          disabled={!isSaveEnabled}
        />
      </Grid>
    </Grid>
  );
};

const SurveyInvitation = ({ id }) => {
  const [{ displayName: userName }] = useAuth();
  const [{ name: companyName }] = useAccountData();

  const [invitation, setInvitation] = useState({});
  const [invitationSent, setInvitationSent] = useState(false);
  const [inviteError, setInviteError] = useState("");

  const { name, email = "" } = invitation;

  const editInvitation = (field) => (value) =>
    editObjectData(setInvitation, field, value);

  const onSendInvite = async () => {
    analyticsTrack("Vendor Invited");
    const { success: isExistingUser } = await fetchOurApi({
      path: "/onboarding/check-email",
      method: "POST",
      data: { email },
      callback: (res) => res,
    });

    if (isExistingUser) {
      return setInviteError("This email is already tied to an existing user.");
    }

    return fetchOurApi({
      path: "/vendors/new-vendor-survey-invite",
      method: "POST",
      data: {
        id,
        recipientName: name,
        email,
        companyName,
        senderName: userName,
      },
      callback: () => setInvitationSent(true),
    });
  };

  return (
    <>
      {invitationSent ? (
        <Typography variant="h6" align="center">
          We've sent your invitation. Their information will be updated in your
          dashboard once they've filled out their survey.
        </Typography>
      ) : (
        <Grid container spacing={2} direction="column">
          <Grid item>
            <Typography variant="h6" align="center">
              We're estimating this vendor's emissions because they haven't
              filled out their vendor survey yet. Invite them?
            </Typography>
          </Grid>
          <Grid item>
            <TextField
              label="Vendor Contact Name"
              value={name}
              setValue={editInvitation("name")}
            />
          </Grid>
          <Grid item>
            <TextField
              label="Vendor Contact Email"
              value={email}
              setValue={editInvitation("email")}
              helperText={inviteError}
              error={!!inviteError}
            />
          </Grid>
          <Grid item container justifyContent="center">
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                disabled={!name || !emailRegExpTest(email)}
                onClick={onSendInvite}
              >
                Invite Vendor
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

const CompletedSurveysTimeline = ({ surveys }) => {
  const { palette } = useTheme();

  const CompletedSurveyText = ({ year, invitationEmail, dateCompleted }) => (
    <>
      <Typography variant="h4">{year}</Typography>
      <Typography variant="subtitle2">
        {invitationEmail} completed the survey for this vendor on{" "}
        {formatDate(dateCompleted)}
      </Typography>
    </>
  );

  if (surveys.length === 1) {
    const [survey] = surveys;
    return (
      <DefaultPaper style={{ backgroundColor: palette.backgroundGray.main }}>
        <Grid container spacing={2} wrap="nowrap" alignItems="center">
          <Grid item>
            <Avatar style={{ backgroundColor: palette.secondary.main }}>
              <CheckIcon />
            </Avatar>
          </Grid>
          <Grid item>
            <CompletedSurveyText {...survey} />
          </Grid>
        </Grid>
      </DefaultPaper>
    );
  }

  return (
    <Timeline>
      {surveys.map(({ year, ...otherProps }, idx) => (
        <TimelineItem key={`completed-survey-timeline-item-${idx}`}>
          <TimelineOppositeContent>{year}</TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot>
              <Avatar style={{ backgroundColor: palette.secondary.main }}>
                <CheckIcon />
              </Avatar>
              <TimelineConnector />
            </TimelineDot>
          </TimelineSeparator>
          <TimelineContent>
            <DefaultPaper
              style={{ backgroundColor: palette.backgroundGray.main }}
            >
              <CompletedSurveyText {...otherProps} year={year} />
            </DefaultPaper>
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
};

const IncompleteSurveyStepper = ({
  dateStarted,
  dateInvited,
  invitationEmail,
}) => {
  const redactEmail = () => {
    if (!invitationEmail) {
      return "";
    }

    const [name, url] = invitationEmail.split("@");
    const redactedName = name.split("").map((letter, idx) => {
      if (idx <= 1 || name.length - 3 < idx) {
        return letter;
      }
      return "*";
    });

    return `${redactedName.join("")}@${url}`;
  };
  const redactedEmail = redactEmail();

  const surveySteps = [
    {
      completed: true,
      isActive: !dateStarted,
      label: `${redactedEmail} received the survey invitation on ${formatDate(
        dateInvited
      )}`,
    },
    {
      complete: !!dateStarted,
      isActive: !dateStarted,
      label: !!dateStarted
        ? `${redactedEmail} started the survey on ${formatDate(dateStarted)}`
        : `${redactedEmail} hasn't started the survey yet`,
    },
    {
      completed: false,
      isActive: !!dateStarted,
      label: `${redactedEmail} hasn't completed the survey yet.`,
    },
  ];
  const activeStep = surveySteps.findIndex(({ isActive }) => isActive);

  return (
    <Stepper activeStep={activeStep} orientation="vertical">
      {surveySteps.map(({ label, completed }, idx) => (
        <Step key={`incomplete-survey-step-${idx}`} completed={completed}>
          <StepLabel>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

const SurveyDetailsView = ({ surveyStatus, id, surveys = [] }) => {
  if (surveyStatus === "no-survey") {
    return <SurveyInvitation id={id} />;
  }

  const completedSurveys = surveys.filter(({ dateCompleted }) => dateCompleted);
  const incompleteSurvey = surveys.find(({ dateCompleted }) => !dateCompleted);

  return (
    <Grid container spacing={2} direction="column">
      {!!incompleteSurvey && (
        <>
          <Grid item>
            <Typography variant="h3">Current Survey</Typography>
          </Grid>
          <Grid item>
            <IncompleteSurveyStepper {...incompleteSurvey} />
          </Grid>
        </>
      )}
      {!!completedSurveys.length && (
        <>
          <Grid item>
            <Typography variant="h3">Completed Surveys</Typography>
          </Grid>
          <Grid item>
            <CompletedSurveysTimeline surveys={completedSurveys} />
          </Grid>
        </>
      )}
    </Grid>
  );
};

const VendorDetailsSlider = ({
  selectedVendor,
  setSelectedVendor,
  setSelectedTransaction,
}) => {
  const { id, transactions, name, status, surveyStatus, surveys } =
    selectedVendor;

  const { updateCollectionDoc } = useCachedFirebaseCrud();

  const [matchingCompaniesLoading, setMatchingCompaniesLoading] =
    useState(true);
  const [matchingCompanies, setMatchingCompanies] = useState([]);
  const [editableVendor, setEditableVendor] = useState(selectedVendor);
  const { emissionCategory, naicsCode, scopeThreeCategory, description } =
    editableVendor;

  const [selectedMatchCompany, setSelectedMatchCompany] = useState(null);

  const editVendor = (field) => (value) =>
    editObjectData(setEditableVendor, field, value);

  const isSaveEnabled = () => {
    const findAreAllPropertiesSame = () => {
      if (!!selectedMatchCompany) {
        return false;
      }

      const { emissionCategory: originalEmissionCategory } = selectedVendor;
      if (emissionCategory !== "spend-based") {
        return emissionCategory === originalEmissionCategory;
      }

      const {
        naicsCode: originalNaicsCode,
        scopeThreeCategory: originalScopeThreeCategory,
      } = selectedVendor;

      return (
        originalNaicsCode === naicsCode &&
        originalScopeThreeCategory === scopeThreeCategory
      );
    };

    const areAllPropertiesSame = findAreAllPropertiesSame();

    const areAllInputsComplete =
      !!emissionCategory &&
      (emissionCategory === "spend-based"
        ? naicsCode && scopeThreeCategory
        : true);

    return !areAllPropertiesSame && areAllInputsComplete;
  };

  useEffect(() => {
    const fetchAndSetMatchingCompanies = async () => {
      if (status !== "unmatched") {
        return setMatchingCompaniesLoading(false);
      }

      setMatchingCompaniesLoading(true);
      setSelectedMatchCompany(null);
      setEditableVendor(selectedVendor);
      const matchingCompanies = await companyMatchingFunction(name);

      setMatchingCompaniesLoading(false);

      if (!matchingCompanies?.length) {
        return setMatchingCompanies([]);
      }

      return setMatchingCompanies(matchingCompanies);
    };

    fetchAndSetMatchingCompanies();
  }, [matchingCompanies.length, name, status, selectedVendor]);

  const emissionsTableTab =
    !emissionCategory && transactions?.length
      ? [
          {
            label: "Transactions",
            value: "transactions",
            content: (
              <EmissionsDetailsTableAccordion
                emissions={transactions}
                type="vendors"
                isExpanded
              />
            ),
          },
        ]
      : [];

  const emissionsDetailsArray =
    status === "confirmed"
      ? [
          {
            label: "Emissions",
            value: "emissions",
            content: (
              <EmissionsDetails
                setSelectedTransaction={setSelectedTransaction}
                emissions={transactions}
                type="vendors"
                name={name}
                chartEmissions={transactions}
                closeSelectedObjectSlider={() => setSelectedVendor(null)}
              />
            ),
          },
        ]
      : [];

  const surveyTabArray =
    status === "unmatched" || surveyStatus === "has-account"
      ? []
      : [
          {
            label: "Survey",
            value: "survey",
            content: (
              <SurveyDetailsView
                surveyStatus={surveyStatus}
                id={id}
                surveys={surveys}
              />
            ),
          },
        ];

  const views = [
    ...emissionsDetailsArray,
    {
      label: "Details",
      value: "details",
      content: (
        <EditVendorDetails
          selectedVendor={selectedVendor}
          editVendor={editVendor}
          matchingCompanies={matchingCompanies}
          matchingCompaniesLoading={matchingCompaniesLoading}
          selectedCompany={selectedMatchCompany}
          setSelectedCompany={setSelectedMatchCompany}
          vendor={editableVendor}
          setEditableVendor={setEditableVendor}
        />
      ),
      footer: (
        <EditVendorFooter
          matchingCompany={selectedMatchCompany}
          editableVendor={editableVendor}
          setSelectedVendor={setSelectedVendor}
          isSaveEnabled={isSaveEnabled()}
          matchingCompanies={matchingCompanies}
        />
      ),
    },
    ...emissionsTableTab,
    ...surveyTabArray,
  ];

  const footerInputSubcategories = [
    { subcategory: emissionCategory },
    ...(emissionCategory === "spend-based"
      ? []
      : [{ subcategory: "spend-based" }]),
  ];

  return (
    <SettingsObjectDetailsSlider
      type="vendors"
      settingsObject={selectedVendor}
      onNameSave={(name) => {
        editVendor("name")(name);
        return updateCollectionDoc(
          status === "unmatched" ? "vendors" : "vendor-companies",
          id,
          { name }
        );
      }}
      views={views}
      setSelectedObject={setSelectedVendor}
      footerInputSubcategories={footerInputSubcategories}
      editNameDisabled={surveyStatus === "has-account"}
      leftContent={
        description && (
          <ThemeProvider theme={mergeDarkTheme}>
            <Container maxWidth="sm">
              <Typography variant="h5" color="textPrimary" align="center">
                {description}
              </Typography>
            </Container>
          </ThemeProvider>
        )
      }
    />
  );
};
export default VendorDetailsSlider;
