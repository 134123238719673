import React, { useState, useContext, useEffect } from "react";

import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";

import {
  Box,
  Avatar,
  Grid,
  Skeleton,
  IconButton,
  Tooltip,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tabs,
  Tab,
  useTheme,
} from "@mui/material";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faChartBar,
  faChartLineDown,
  faMagnifyingGlassChart,
  faCaretCircleLeft,
  faCaretCircleRight,
  faCircleXmark,
} from "@fortawesome/pro-solid-svg-icons";

import {
  ToggleButtons,
  CategoriesAvatar,
  LoadingButton,
} from "@aclymatepackages/atoms";
import {
  formatDate,
  ucFirstLetters,
  formatDecimal,
} from "@aclymatepackages/formatters";
import { sumTonsCo2e } from "@aclymatepackages/other-helpers";
import { EmissionsPieChart } from "@aclymatepackages/modules";
import findSubcategory, {
  findScope,
  buildScopesWithColors,
  buildSubcategoriesArray,
} from "@aclymatepackages/subcategories";
import { filterByDateRange } from "@aclymatepackages/date-helpers";
import { buildEmissionGroupData } from "@aclymatepackages/chart-helpers";

import PrimaryViewTable from "./PrimaryViewTable";
import Card from "../../../../atoms/mui/Card";

import ErrorBoundary from "../../../../atoms/ErrorBoundary";
import ButtonMenu from "../../../../atoms/buttons/ButtonMenu";

import DashboardViewLayout from "../../../../layouts/DashboardViewLayout";
import ViewGraphCardLayout from "../../../../layouts/ViewGraphCard";

import EmissionsVolumeForm from "../../../../inputs/emissions/EmissionsVolumeForm";

import useToggleableEmissionsDetailsChart from "../../../../modules/charts/useToggleableEmissionsDetailsChart";
import LabeledEmissionsChart from "../../../../modules/charts/LabeledEmissionsChart";
import FilterChips from "../../../../modules/filter/FilterChips";
import DateIntervalToggles from "../../../../modules/DateIntervalToggles";
import DateRangeFilter from "../../../../modules/filter/DateRangeFilter";

import ManualTransactionsInput from "../../../../inputs/emissions/ManualTransactionsInput";
import MultipleTransactionsUploader from "../../../../inputs/csv-uploaders/MultipleTransactionsUploader";

import { PlatformLayoutContext } from "../../../../../helpers/contexts/platformLayout";
import { useAccountData, useAuth } from "../../../../../helpers/firebase";
import { useEmissionsChartData } from "../../../../../helpers/components/emissions";
import { fetchOurApi } from "../../../../../helpers/utils/apiCalls";

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

const findSelectedTimePeriodFilterDates = (selectedTimePeriod, graphPeriod) => {
  if (graphPeriod === "month") {
    const [month, year] = selectedTimePeriod.split("-");
    const startDate = new Date(`20${year}`, month - 1, 1);
    const endDate = new Date(`20${year}`, month, 0);
    return { startDate, endDate };
  }

  if (graphPeriod === "quarter") {
    const [quarter, year] = selectedTimePeriod.split(" ");
    const quarterNumber = quarter.charAt(1);
    const startDate = new Date(year, quarterNumber * 3 - 3, 1);
    const endDate = new Date(year, quarterNumber * 3, 0);
    return { startDate, endDate };
  }

  return {
    startDate: new Date(selectedTimePeriod, 0, 1),
    endDate: new Date(selectedTimePeriod, 11, 31),
  };
};

const filterEmissionsByChips = ({ emission, selectedChips, viewMode }) => {
  const filterBySubcategory = ({ subcategory }) => {
    if (selectedChips.includes("all")) {
      return true;
    }

    return selectedChips.includes(subcategory);
  };

  const filterByScope = ({ scope }) => {
    if (selectedChips.includes("all")) {
      return true;
    }
    return selectedChips.includes(scope);
  };
  if (viewMode === "subcategories") {
    return filterBySubcategory(emission);
  }
  return filterByScope(emission);
};

// const DuplicateTransactionsGroupAccordion = ({
//   duplicateTransactionsGroup,
//   isSelected,
//   onSelectTransactionGroup,
//   onNotDuplicateTransactions,
//   onSelectRow,
//   selectedRows,
// }) => {
//   const { duplicateTransactions } = duplicateTransactionsGroup;
//   const [{ subcategory }] = duplicateTransactions;

//   const areRowsSelectable = duplicateTransactions.length > 2;

//   return (
//     <Grid item>
//       <Paper style={{ overflow: "hidden" }}>
//         <EmissionsDetailsTableAccordion
//           onChange={onSelectTransactionGroup}
//           avatar={<CategoriesAvatar subcategory={subcategory} />}
//           type="primary"
//           title={`${duplicateTransactions.length} ${ucFirstLetters(
//             subcategory
//           )} Transactions`}
//           subtitle={
//             isSelected &&
//             areRowsSelectable &&
//             "You can deselect transactions that are not duplicates."
//           }
//           emissions={duplicateTransactions}
//           isExpanded={isSelected}
//           action={
//             <Tooltip title="None of these transactions are duplicates">
//               <IconButton onClick={onNotDuplicateTransactions}>
//                 <CancelIcon />
//               </IconButton>
//             </Tooltip>
//           }
//           onSelectRow={areRowsSelectable && onSelectRow}
//           selectedRows={selectedRows}
//           noSort
//         />
//       </Paper>
//     </Grid>
//   );
// };

// const DuplicateTransactionsInterface = ({
//   setShowDuplicateTransactions,
//   duplicateTransactionsGroups,
// }) => {
//   const findGroupDuplicateTransactions = (
//     idx,
//     groups = duplicateTransactionsGroups
//   ) => groups[idx]?.duplicateTransactions;

//   const findDisplayTransactionFromIdx = (idx, groups) =>
//     findGroupDuplicateTransactions(idx, groups)?.[0];

//   const buildSelectedTransactionsIdxsArray = (idx) => {
//     const duplicateTransactions = findGroupDuplicateTransactions(idx);
//     if (!duplicateTransactions?.length) {
//       return [];
//     }

//     return [...Array(duplicateTransactions.length)].map(() => true);
//   };

//   const [mergeLoading, setMergeLoading] = useState(false);
//   const [displayGroups, setDisplayGroups] = useState(
//     duplicateTransactionsGroups
//   );
//   const [selectedGroupIdx, setSelectedGroupIdx] = useState(0);
//   const [displayTransaction, setDisplayTransaction] = useState(
//     findDisplayTransactionFromIdx(0)
//   );
//   const [selectedTransactionsIdxs, setSelectedTransactionsIdxs] = useState(
//     buildSelectedTransactionsIdxsArray(0)
//   );

//   const editTransaction = (field) => (value) =>
//     editObjectData(setDisplayTransaction, field, value);

//   const changeSelectedGroup = (idx, displayGroups) => {
//     setSelectedTransactionsIdxs(buildSelectedTransactionsIdxsArray(idx));
//     setSelectedGroupIdx(idx);
//     return setDisplayTransaction(
//       findDisplayTransactionFromIdx(idx, displayGroups)
//     );
//   };

//   const onSelectTransactionGroup = (idx) => () => changeSelectedGroup(idx);

//   const onGroupClose = () => {
//     if (displayGroups.length === 1) {
//       return setShowDuplicateTransactions(false);
//     }

//     const newDisplayGroups = displayGroups.filter(
//       (_, idx) => idx !== selectedGroupIdx
//     );
//     const newSelectedGroupIdx = selectedGroupIdx
//       ? selectedGroupIdx - 1
//       : selectedGroupIdx;

//     setDisplayGroups(newDisplayGroups);
//     return changeSelectedGroup(newSelectedGroupIdx, newDisplayGroups);
//   };

//   //TODO: this is almost certainly wrong
//   //It will need to be retested once we get through this current performance issue
//   const buildNotDuplicatesUpdateObj = (duplicateTransactions) =>
//     duplicateTransactions.map(({ id }) =>
//       duplicateTransactions
//         .filter((duplicateTransaction) => duplicateTransaction.id !== id)
//         .map(({ id }) => id)
//     );

//   const onMergeTransaction = async () => {
//     setMergeLoading(true);
//     const groupDuplicateTransactions =
//       findGroupDuplicateTransactions(selectedGroupIdx);

//     const duplicateTransactions = groupDuplicateTransactions.filter(
//       (_, idx) => selectedTransactionsIdxs[idx]
//     );
//     const nonDuplicateTransactions = groupDuplicateTransactions.filter(
//       (_, idx) => !selectedTransactionsIdxs[idx]
//     );

//     const [mainTransaction, ...otherTransactions] = duplicateTransactions;

//     const { id: mainTransactionId } = mainTransaction || {};
//     const updateMainTransaction = async () =>
//       await onUpdateTransaction(mainTransactionId, displayTransaction);

//     const updateOtherTransactions = async () =>
//       await onBatchUpdateTransactions(
//         otherTransactions.map(({ id }) => id),
//         { archived: true }
//       );

//     const updateNonDuplicateTransactions = async () => {
//       if (!nonDuplicateTransactions.length) {
//         return;
//       }

//       const nonDuplicateTransactionsUpdateObjs = buildNotDuplicatesUpdateObj(
//         nonDuplicateTransactions
//       );

//       return await Promise.all(
//         nonDuplicateTransactionsUpdateObjs.map(
//           async ({ id, notDuplicates }) =>
//             await onUpdateTransaction(id, { notDuplicates })
//         )
//       );
//     };

//     await Promise.all([
//       updateMainTransaction(),
//       updateOtherTransactions(),
//       updateNonDuplicateTransactions(),
//     ]);

//     onGroupClose();
//     return setMergeLoading(false);
//   };

//   //TODO: This will need to be retested once we reactivate this after fixing the performance issues
//   const onNotDuplicateTransactions =
//     ({ duplicateTransactions }) =>
//     () => {
//       const nonDuplicateTransactionsUpdateObjs = buildNotDuplicatesUpdateObj(
//         duplicateTransactions
//       );

//       Promise.all(
//         nonDuplicateTransactionsUpdateObjs.map(
//           async ({ id, notDuplicates }) =>
//             await onUpdateTransaction(id, { notDuplicates })
//         )
//       );

//       return onGroupClose();
//     };

//   const onSelectRow = (idx) => () =>
//     setSelectedTransactionsIdxs((current) =>
//       current.map((value, index) => {
//         if (index === idx) {
//           return !value;
//         }
//         return value;
//       })
//     );

//   return (
//     <TransactionDetailsBlock
//       saveTransaction={onMergeTransaction}
//       transaction={displayTransaction}
//       editTransaction={editTransaction}
//       setIsSlideOpen={setShowDuplicateTransactions}
//       saveButtonText="Merge Transactions"
//       isButtonLoading={mergeLoading}
//       closeOnSave={false}
//       slideLeftPanel={
//         <Container
//           maxWidth="sm"
//           style={{ maxHeight: "100%", overflowY: "auto" }}
//         >
//           <Grid container direction="column" spacing={2}>
//             <Grid item>
//               <Typography
//                 variant="h4"
//                 style={{ color: "white" }}
//                 align="center"
//               >
//                 Use the form on the right to review and merge your duplicate
//                 transactions.
//               </Typography>
//             </Grid>
//             {displayGroups.map((duplicateTransactionsGroup, idx) => (
//               <DuplicateTransactionsGroupAccordion
//                 key={`duplicate-transaction-group-${idx}`}
//                 duplicateTransactionsGroup={duplicateTransactionsGroup}
//                 isSelected={selectedGroupIdx === idx}
//                 onSelectTransactionGroup={onSelectTransactionGroup(idx)}
//                 onNotDuplicateTransactions={onNotDuplicateTransactions(
//                   duplicateTransactionsGroup
//                 )}
//                 onSelectRow={onSelectRow}
//                 selectedRows={selectedTransactionsIdxs}
//               />
//             ))}
//           </Grid>
//         </Container>
//       }
//     />
//   );
// };

const AddTransactionsMenu = () => {
  const [dialogOpen, setDialogOpen] = useState(false);

  const inputDialogs = {
    transactionsCsv: (
      <MultipleTransactionsUploader
        setOpen={setDialogOpen}
        open={!!dialogOpen}
      />
    ),
    manualTransactions: (
      <ManualTransactionsInput setDialogOpen={setDialogOpen} />
    ),
    volumeTransactions: <EmissionsVolumeForm setDialogOpen={setDialogOpen} />,
  };

  return (
    <>
      {dialogOpen && inputDialogs[dialogOpen]}
      <ButtonMenu
        color="primary"
        menuOptions={[
          {
            label: "Bulk Upload Emissions",
            onClick: () => setDialogOpen("transactionsCsv"),
          },
          {
            label: "Add a single emission",
            onClick: () => setDialogOpen("manualTransactions"),
          },
          {
            label: "Add Emission Volume",
            onClick: () => setDialogOpen("volumeTransactions"),
          },
        ]}
      />
    </>
  );
};

const FullTransactionsChart = ({
  selectedTimePeriod,
  graphPeriod,
  viewMode,
  emissions,
  selectedChips,
}) => {
  const [tableEmissions, setTableEmissions] = useState([]);
  const [transactionsLoading, setTransactionsLoading] = useState(false);

  const { startDate, endDate } = findSelectedTimePeriodFilterDates(
    selectedTimePeriod,
    graphPeriod
  );

  useEffect(() => {
    setTableEmissions([]);
  }, [selectedTimePeriod]);

  const onFetchTransactions = async () => {
    setTransactionsLoading(true);

    const fullAccountId = window.sessionStorage.getItem("accountId");
    const transactions = await fetchOurApi({
      path: "/transactions/fetch-period-transactions",
      method: "POST",
      data: { startDate, endDate, accountId: fullAccountId },
      callback: ({ transactions }) => transactions,
    });

    const defaultRecurringEmissions = emissions.filter(
      ({ source }) => source !== "aggregated"
    );

    setTableEmissions([...transactions, ...defaultRecurringEmissions]);
    return setTransactionsLoading(false);
  };

  const filteredEmissions = tableEmissions.filter((emission) =>
    filterEmissionsByChips({ emission, selectedChips, viewMode })
  );

  return (
    <>
      {!!tableEmissions.length ? (
        <Grid item>
          <PrimaryViewTable viewMode={viewMode} emissions={filteredEmissions} />
        </Grid>
      ) : (
        <Grid item container justifyContent="center">
          <Grid item>
            <LoadingButton
              label="Fetch All Emissions"
              variant="contained"
              color="primary"
              isLoading={transactionsLoading}
              onClick={onFetchTransactions}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

const PrimaryViewChart = ({
  graphPeriod,
  setGraphPeriod,
  filterStartDate,
  setFilterStartDate,
  filterEndDate,
  setFilterEndDate,
  chartData,
  chartArray,
  emissionsLoading,
  selectedBar,
  setSelectedBar,
  setChartProps,
}) => {
  const onBarClick = (barData, idx) => setSelectedBar({ ...barData, idx });

  const onSetGraphPeriod = (period) => {
    setSelectedBar(null);
    return setGraphPeriod(period);
  };

  return (
    <>
      {emissionsLoading ? (
        <Skeleton variant="rectangular" height={250} />
      ) : (
        <ViewGraphCardLayout
          gridProps={{ xs: 12 }}
          title="Emissions History"
          subtitle="Click on any bar to view a summary of emissions during that period."
          graphs={[
            {
              type: "bar",
              icon: faChartBar,
              Graph: LabeledEmissionsChart,
              graphProps: {
                type: "bar",
                onBarClick,
                chartArray,
                setChartProps,
                selectedBar,
                showTooltip: true,
              },
            },
          ]}
          action={
            <Grid container>
              <Grid item>
                <DateRangeFilter
                  filterStartDate={filterStartDate}
                  setFilterStartDate={setFilterStartDate}
                  filterEndDate={filterEndDate}
                  setFilterEndDate={setFilterEndDate}
                />
              </Grid>
              <Grid item>
                <DateIntervalToggles
                  graphPeriod={graphPeriod}
                  setGraphPeriod={onSetGraphPeriod}
                />
              </Grid>
            </Grid>
          }
          color="secondary"
          dataArray={chartData}
          icon={<FontAwesomeIcon icon={faChartLineDown} />}
          analyticsCta="to see how your emissions have changed monthly, quarterly, or yearly"
        />
      )}
    </>
  );
};

const EmissionsSummaryTable = ({
  selectedTimePeriod,
  emissions,
  viewMode,
  graphPeriod,
}) => {
  const [{ startDate }] = useAccountData();
  const { displayUnitLabel, convertCarbonUnits } = useContext(
    PlatformLayoutContext
  );

  const buildTableData = () => {
    const dataField = viewMode === "scopes" ? "scope" : "subcategory";

    if (graphPeriod === "month") {
      const tableRows =
        viewMode === "scopes"
          ? buildScopesWithColors()
          : buildSubcategoriesArray(emissions);

      const tableData = tableRows.map((row) => {
        const rowEmissions = emissions.filter(
          (emission) => emission[dataField] === row[dataField]
        );

        return {
          ...row,
          emissions: [{ tonsCo2e: sumTonsCo2e(rowEmissions) }],
        };
      });

      const sortedTableData = tableData.sort(
        (a, b) => b.emissions[0].tonsCo2e - a.emissions[0].tonsCo2e
      );

      return {
        tableData: sortedTableData,
        labelsArray: [
          `${selectedTimePeriod} ${ucFirstLetters(displayUnitLabel)} CO2e`,
        ],
      };
    }

    const {
      groupedEmissions,
      scopesArray,
      subcategoriesArray,
      chartLabelsArray,
    } = buildEmissionGroupData(emissions, "month", startDate);

    const tableRows = viewMode === "scopes" ? scopesArray : subcategoriesArray;

    const categoriesRowsData = tableRows.map((row) => {
      const groupedRowEmissions = groupedEmissions.map((emissions) =>
        emissions.filter((emission) => emission[dataField] === row[dataField])
      );

      const groupedRowEmissionsSums = groupedRowEmissions.map((emissions) => ({
        tonsCo2e: sumTonsCo2e(emissions),
      }));
      const rowEmissionsTotalTons = groupedRowEmissionsSums.reduce(
        (sum, { tonsCo2e }) => sum + tonsCo2e,
        0
      );

      return {
        ...row,
        tonsCo2e: rowEmissionsTotalTons,
        emissions: [
          ...groupedRowEmissionsSums,
          { tonsCo2e: rowEmissionsTotalTons },
        ],
      };
    });

    const totalRowEmissions = groupedEmissions.map((emissions) => ({
      tonsCo2e: sumTonsCo2e(emissions),
    }));

    const tableData = [
      ...categoriesRowsData.sort((a, b) => b.tonsCo2e - a.tonsCo2e),
      { label: "Total", emissions: totalRowEmissions },
    ];

    return {
      tableData,
      labelsArray: [...chartLabelsArray, "Total"],
    };
  };

  const { tableData, labelsArray } = buildTableData();

  return (
    <TableContainer sx={{ maxHeight: "100%", maxWidth: "85vw" }}>
      <Table size="small" stickyHeader>
        <TableHead>
          <TableRow>
            {[
              graphPeriod !== "year" ? "Emission Type" : "",
              ...labelsArray,
            ].map((value, idx) => (
              <TableCell key={`header-cell-${idx}`}>
                <Typography
                  noWrap
                  variant={graphPeriod === "year" ? "h6" : "h5"}
                >
                  {value}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData.map(({ subcategory, scope, emissions, label }, idx) => (
            <TableRow key={`emissions-table-row-${idx}`}>
              <TableCell>
                <Grid container spacing={2} alignItems="center" wrap="nowrap">
                  <Grid item>
                    {!label && (
                      <CategoriesAvatar
                        viewMode={viewMode}
                        subcategory={subcategory}
                        scope={scope}
                      />
                    )}
                  </Grid>
                  {graphPeriod !== "year" && (
                    <Grid item>
                      <Typography variant="body1">
                        {label ||
                          (viewMode === "subcategories"
                            ? ucFirstLetters(findSubcategory(subcategory).name)
                            : `Scope ${scope}`)}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </TableCell>
              {emissions.map(({ tonsCo2e }, idx) => (
                <TableCell key={`cell-${idx}`}>
                  <Typography variant="body2">
                    {formatDecimal(convertCarbonUnits(tonsCo2e))}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const DetailsEmissionChart = ({ emissions, graphPeriod, viewMode }) => {
  const { chart, toggles } = useToggleableEmissionsDetailsChart(
    emissions,
    graphPeriod === "month" ? "pie" : "bar",
    { height: "100%", aspect: null, viewMode, graphPeriod: "month" }
  );

  if (graphPeriod === "month") {
    return <Box sx={{ height: "100%" }}>{chart}</Box>;
  }

  if (graphPeriod === "quarter") {
    return (
      <Box display="flex" flexDirection="column" sx={{ height: "100%" }}>
        <Box flexGrow={1}>{chart}</Box>
        <Box flexGrow={0}>
          <Grid container justifyContent="center">
            <Grid item>{toggles}</Grid>
          </Grid>
        </Box>
      </Box>
    );
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={8}>
        {chart}
      </Grid>
      <Grid item xs={4}>
        <EmissionsPieChart dataArray={emissions} />
      </Grid>
    </Grid>
  );
};

const MonthQuarterDetailsBlock = ({
  emissions,
  selectedTimePeriod,
  viewMode,
  graphPeriod,
  selectedChips,
}) => {
  return (
    <>
      <Grid item>
        {
          <Grid
            container
            spacing={4}
            sx={{ height: "50vh" }}
            alignItems="stretch"
          >
            <Grid item xs={6} sx={{ height: "100%" }}>
              <EmissionsSummaryTable
                selectedTimePeriod={selectedTimePeriod}
                emissions={emissions}
                viewMode={viewMode}
                graphPeriod={graphPeriod}
              />
            </Grid>
            <Grid item xs={6} sx={{ height: "100%" }}>
              <DetailsEmissionChart
                emissions={emissions}
                graphPeriod={graphPeriod}
                viewMode={viewMode}
              />
            </Grid>
          </Grid>
        }
      </Grid>
      {graphPeriod === "month" && (
        <FullTransactionsChart
          selectedTimePeriod={selectedTimePeriod}
          graphPeriod={graphPeriod}
          viewMode={viewMode}
          emissions={emissions}
          selectedChips={selectedChips}
        />
      )}
    </>
  );
};

const YearDetailsBlock = ({
  emissions,
  selectedTimePeriod,
  viewMode,
  graphPeriod,
}) => {
  const [selectedTab, setSelectedTab] = useState("table");

  const tabs = [
    {
      label: "Table",
      value: "table",
      content: (
        <Box sx={{ width: "100%", overflowX: "auto" }}>
          <EmissionsSummaryTable
            emissions={emissions}
            selectedTimePeriod={selectedTimePeriod}
            viewMode={viewMode}
            graphPeriod={graphPeriod}
          />
        </Box>
      ),
    },
    {
      label: "Charts",
      value: "charts",
      content: (
        <Box sx={{ height: "50vh" }}>
          <DetailsEmissionChart
            emissions={emissions}
            viewMode={viewMode}
            graphPeriod={graphPeriod}
          />
        </Box>
      ),
    },
  ];

  const { content } = tabs.find(({ value }) => value === selectedTab);

  return (
    <Grid item>
      <Tabs
        value={selectedTab}
        onChange={(_, value) => setSelectedTab(value)}
        fullWidth
      >
        {tabs.map(({ label, value }, idx) => (
          <Tab
            label={label}
            value={value}
            key={`object-details-view-tab-${idx}`}
          />
        ))}
      </Tabs>
      {content}
    </Grid>
  );
};

const IntervalDetailsBlock = ({
  emissions,
  viewMode,
  selectedTimePeriod,
  graphPeriod,
}) => {
  const { palette } = useTheme();
  const [user] = useAuth();
  const { displayName, email } = user || [];
  const { activateSnackbar } = useContext(PlatformLayoutContext);

  const [selectedChips, setSelectedChips] = useState(["all"]);
  const [downloadBtnClicked, setDownloadBtnClicked] = useState(false);

  const buildChipsArray = () => {
    if (viewMode === "subcategories") {
      const uniqueEmissionsSubcategories = [
        ...new Set(emissions.map(({ subcategory }) => subcategory)),
      ];

      return uniqueEmissionsSubcategories.map((subcategory) => {
        const subcategoryData = findSubcategory(subcategory);
        return {
          ...subcategoryData,
          id: subcategory,
        };
      });
    }

    const uniqueEmissionsScopes = [
      ...new Set(emissions.map(({ scope }) => scope)),
    ].filter((scope) => scope);

    return uniqueEmissionsScopes.map((scope) => {
      const { color, name } = findScope(scope);

      return {
        name: `Scope ${name}`,
        id: scope,
        color: palette[color].main,
      };
    });
  };

  const filteredEmissions = emissions.filter((emission) =>
    filterEmissionsByChips({ emission, selectedChips, viewMode })
  );

  const onDownloadBtnClick = async () => {
    const { startDate, endDate } = findSelectedTimePeriodFilterDates(
      selectedTimePeriod,
      graphPeriod
    );

    const fullAccountId = window.sessionStorage.getItem("accountId");
    return await fetchOurApi({
      path: "/transactions/send-account-transactions-download-email",
      method: "POST",
      data: {
        startDate,
        endDate,
        accountId: fullAccountId,
        emailAddress: email,
        firstName: displayName.split(" ")[0],
        period: selectedTimePeriod,
      },
      callback: ({ success }) => {
        setDownloadBtnClicked(!!success);
        return activateSnackbar({
          message: success
            ? "You should receive an email with your emissions soon."
            : "An error occurred in trying to download your emissions",
          severity: success ? "success" : "error",
        });
      },
    });
  };

  const sharedDisplayBlockProps = {
    emissions: filteredEmissions,
    viewMode,
    graphPeriod,
    selectedTimePeriod,
  };

  return (
    <Grid container spacing={6} direction="column">
      <Grid
        item
        container
        spacing={2}
        justifyContent="space-between"
        alignItems="center"
        wrap="nowrap"
      >
        <Grid item>
          <FilterChips
            chipsArray={buildChipsArray()}
            selectedChips={selectedChips}
            setSelectedChips={setSelectedChips}
          />
        </Grid>
        <Grid item>
          <Tooltip
            title={
              downloadBtnClicked
                ? "You should receive an email with your emissions soon."
                : `Download all emissions for this ${graphPeriod}`
            }
          >
            <span>
              <IconButton
                disabled={downloadBtnClicked}
                onClick={() => onDownloadBtnClick()}
              >
                <FontAwesomeIcon icon={faDownload} />
              </IconButton>
            </span>
          </Tooltip>
        </Grid>
      </Grid>
      {graphPeriod === "year" ? (
        <YearDetailsBlock {...sharedDisplayBlockProps} />
      ) : (
        <MonthQuarterDetailsBlock
          {...sharedDisplayBlockProps}
          selectedChips={selectedChips}
        />
      )}
    </Grid>
  );
};

const TimePeriodDetails = ({
  chartData,
  emissions,
  graphPeriod,
  viewMode,
  selectedBar,
  setSelectedBar,
}) => {
  const { displayUnitLabel, convertCarbonUnits } = useContext(
    PlatformLayoutContext
  );

  const barsCount = chartData.length;
  const { idx: selectedBarIdx, label: selectedTimePeriod } = selectedBar;

  const onTimePeriodToggle = (newIdx) =>
    setSelectedBar({ ...selectedBarIdx, ...chartData[newIdx], idx: newIdx });

  const { startDate, endDate } = findSelectedTimePeriodFilterDates(
    selectedTimePeriod,
    graphPeriod
  );

  const periodFilteredEmissions = emissions.filter((emission) =>
    filterByDateRange({
      filterEndDate: endDate,
      filterStartDate: startDate,
      ...emission,
    })
  );

  const findTotalEmissionsCount = () => {
    const aggregatedTransactions = periodFilteredEmissions.filter(
      ({ transactionsCount }) => transactionsCount
    );
    const nonAggregatedEmissions = periodFilteredEmissions.filter(
      ({ transactionsCount }) => !transactionsCount
    );
    const aggregatedTransactionsTotalCount = aggregatedTransactions.reduce(
      (sum, { transactionsCount }) => sum + transactionsCount,
      0
    );

    return formatDecimal(
      nonAggregatedEmissions.length + aggregatedTransactionsTotalCount,
      0
    );
  };
  const totalEmissionsSumTons = sumTonsCo2e(periodFilteredEmissions);

  const actionButtons = [
    {
      icon: faCaretCircleLeft,
      onClick: () => onTimePeriodToggle(selectedBarIdx - 1),
      disabled: selectedBarIdx === 0,
    },
    {
      icon: faCaretCircleRight,
      onClick: () => onTimePeriodToggle(selectedBarIdx + 1),
      disabled: selectedBarIdx + 1 === barsCount,
    },
    {
      icon: faCircleXmark,
      onClick: () => setSelectedBar(null),
    },
  ];

  return (
    <Card
      cardType="layout"
      icon={<FontAwesomeIcon icon={faMagnifyingGlassChart} />}
      title={`${selectedTimePeriod} Emissions Summary`}
      subtitle={`You had ${findTotalEmissionsCount()} emissions this ${graphPeriod} that totaled ${formatDecimal(
        convertCarbonUnits(totalEmissionsSumTons)
      )} ${displayUnitLabel}`}
      action={
        <Grid container>
          {actionButtons.map(({ icon, onClick, disabled }, idx) => (
            <Grid item key={`action-button-${idx}`}>
              <IconButton onClick={onClick} disabled={disabled}>
                {<FontAwesomeIcon icon={icon} />}
              </IconButton>
            </Grid>
          ))}
        </Grid>
      }
      content={
        <IntervalDetailsBlock
          emissions={periodFilteredEmissions}
          viewMode={viewMode}
          selectedTimePeriod={selectedTimePeriod}
          graphPeriod={graphPeriod}
          barsCount={barsCount}
        />
      }
    />
  );
};

const PrimaryViewBlock = ({ allEmissions, viewLoading }) => {
  const { palette } = useTheme();
  const [{ startDate: companyStartDate }] = useAccountData();
  const { displayUnitLabel, convertCarbonUnits } = useContext(
    PlatformLayoutContext
  );
  // const { duplicateTransactionsGroups } = useDashboardDataContext();

  // const [showDuplicateTransactions, setShowDuplicateTransactions] =
  //   useState(false);
  const [viewMode, setViewMode] = useState("subcategories");
  const [graphPeriod, setGraphPeriod] = useState("month");
  const [filterStartDate, setFilterStartDate] = useState(null);
  const [filterEndDate, setFilterEndDate] = useState(null);
  const [selectedBar, setSelectedBar] = useState(null);

  const filteredEmissions = allEmissions.filter((emission) =>
    filterByDateRange({ filterEndDate, filterStartDate, ...emission })
  );

  // const transactionAlerts = usePrimaryViewAlerts(setShowDuplicateTransactions);
  const { chartData, chartArray } = useEmissionsChartData({
    emissions: filteredEmissions,
    viewMode,
    graphPeriod,
  });

  const buildSubtitle = () => {
    const emissionsSumTons = sumTonsCo2e(filteredEmissions);
    if (!emissionsSumTons) {
      return "There are no emissions from your company that match this filtering criteria.";
    }

    const buildDateString = () => {
      const formatDateString = (date) => date.format("MMMM YYYY");

      if (filterStartDate && filterEndDate) {
        return `from ${formatDateString(filterStartDate)} to ${formatDateString(
          filterEndDate
        )}`;
      }

      if (filterEndDate) {
        return `before ${formatDateString(filterEndDate)}`;
      }

      if (filterStartDate) {
        return `since ${formatDateString(filterStartDate)}`;
      }

      return `since your start date (${formatDate(companyStartDate)})`;
    };

    return `Your company has emitted ${formatDecimal(
      convertCarbonUnits(emissionsSumTons)
    )} ${displayUnitLabel} of CO2 ${buildDateString()}.`;
  };

  return (
    <>
      {/* {showDuplicateTransactions && (
        <DuplicateTransactionsInterface
          setShowDuplicateTransactions={setShowDuplicateTransactions}
          duplicateTransactionsGroups={duplicateTransactionsGroups}
        />
      )} */}
      <DashboardViewLayout
        // alerts={transactionAlerts}
        type="primary"
        viewLoading={viewLoading}
        title="Carbon Accounting Summary"
        subtitle={buildSubtitle()}
        showFilterButton={false}
        primaryAction={<AddTransactionsMenu />}
        secondaryAction={
          <ToggleButtons
            value={viewMode}
            onChange={(viewMode) => setViewMode(viewMode)}
            buttons={[
              {
                value: "subcategories",
                name: "Types",
                id: "subcategories-option",
              },
              { value: "scopes", name: "Scopes", id: "scopes-option" },
            ]}
          />
        }
        avatar={
          <Avatar style={{ backgroundColor: palette.secondary.main }}>
            <PlaylistAddCheckIcon />
          </Avatar>
        }
        graph={
          <PrimaryViewChart
            chartData={chartData}
            chartArray={chartArray}
            filterStartDate={filterStartDate}
            setFilterStartDate={setFilterStartDate}
            filterEndDate={filterEndDate}
            setFilterEndDate={setFilterEndDate}
            emissionsLoading={viewLoading}
            viewMode={viewMode}
            graphPeriod={graphPeriod}
            setGraphPeriod={setGraphPeriod}
            selectedBar={selectedBar}
            setSelectedBar={setSelectedBar}
          />
        }
        table={
          selectedBar && (
            <TimePeriodDetails
              chartData={chartData}
              emissions={allEmissions}
              graphPeriod={graphPeriod}
              viewMode={viewMode}
              selectedBar={selectedBar}
              setSelectedBar={setSelectedBar}
            />
          )
        }
      />
    </>
  );
};

const PrimaryView = (props) => {
  return (
    <ErrorBoundary>
      <PrimaryViewBlock {...props} />
    </ErrorBoundary>
  );
};
export default PrimaryView;
